.os-size-observer, .os-size-observer-listener {
  direction: inherit;
  pointer-events: none;
  visibility: hidden;
  box-sizing: border-box;
  overflow: hidden;
}

.os-size-observer, .os-size-observer-listener, .os-size-observer-listener-item, .os-size-observer-listener-item-final {
  writing-mode: horizontal-tb;
  position: absolute;
  top: 0;
  left: 0;
}

.os-size-observer {
  z-index: -1;
  contain: strict;
  padding: inherit;
  border: inherit;
  box-sizing: inherit;
  flex-flow: row;
  margin: -133px;
  display: flex;
  inset: 0;
  transform: scale(.1);
}

.os-size-observer:before {
  content: "";
  box-sizing: inherit;
  width: 10px;
  height: 10px;
  flex: none;
  padding: 10px;
}

.os-size-observer-appear {
  animation: 1ms forwards os-size-observer-appear-animation;
}

.os-size-observer-listener {
  box-sizing: border-box;
  padding: inherit;
  border: inherit;
  flex: auto;
  margin: -133px;
  position: relative;
  transform: scale(10);
}

.os-size-observer-listener.ltr {
  margin-left: 0;
  margin-right: -266px;
}

.os-size-observer-listener.rtl {
  margin-left: -266px;
  margin-right: 0;
}

.os-size-observer-listener:empty:before {
  content: "";
  width: 100%;
  height: 100%;
}

.os-size-observer-listener:empty:before, .os-size-observer-listener > .os-size-observer-listener-item {
  padding: inherit;
  border: inherit;
  box-sizing: content-box;
  flex: auto;
  display: block;
  position: relative;
}

.os-size-observer-listener-scroll {
  box-sizing: border-box;
  display: flex;
}

.os-size-observer-listener-item {
  direction: ltr;
  flex: none;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.os-size-observer-listener-item-final {
  transition: none;
}

@keyframes os-size-observer-appear-animation {
  from {
    cursor: auto;
  }

  to {
    cursor: none;
  }
}

.os-trinsic-observer {
  box-sizing: border-box;
  max-width: 0;
  max-height: 1px;
  z-index: -1;
  height: 0;
  contain: strict;
  border: none;
  flex: none;
  margin: 0;
  padding: 0;
  position: relative;
  top: calc(100% + 1px);
  overflow: hidden;
}

.os-trinsic-observer:not(:empty) {
  height: calc(100% + 1px);
  top: -1px;
}

.os-trinsic-observer:not(:empty) > .os-size-observer {
  width: 1000%;
  height: 1000%;
  min-height: 1px;
  min-width: 1px;
}

.os-environment {
  --os-custom-prop: -1;
  opacity: 0;
  visibility: hidden;
  height: 200px;
  width: 200px;
  z-index: var(--os-custom-prop);
  position: fixed;
  overflow: scroll;
}

.os-environment div {
  width: 200%;
  height: 200%;
  margin: 10px 0;
}

.os-environment.os-environment-flexbox-glue {
  height: auto;
  width: auto;
  min-height: 200px;
  min-width: 200px;
  flex-flow: row;
  display: flex;
}

.os-environment.os-environment-flexbox-glue div {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  flex: auto;
  margin: 0;
}

.os-environment.os-environment-flexbox-glue-max {
  max-height: 200px;
}

.os-environment.os-environment-flexbox-glue-max div {
  overflow: visible;
}

.os-environment.os-environment-flexbox-glue-max div:before {
  content: "";
  height: 999px;
  width: 999px;
  display: block;
}

.os-environment, .os-viewport {
  -ms-overflow-style: scrollbar !important;
}

[data-overlayscrollbars-initialize], [data-overlayscrollbars~="scrollbarHidden"], .os-viewport-scrollbar-hidden.os-environment, .os-viewport-scrollbar-hidden.os-viewport {
  scrollbar-width: none !important;
}

[data-overlayscrollbars-initialize]::-webkit-scrollbar, [data-overlayscrollbars-initialize]::-webkit-scrollbar-corner, [data-overlayscrollbars~="scrollbarHidden"]::-webkit-scrollbar, [data-overlayscrollbars~="scrollbarHidden"]::-webkit-scrollbar-corner, .os-viewport-scrollbar-hidden.os-environment::-webkit-scrollbar, .os-viewport-scrollbar-hidden.os-environment::-webkit-scrollbar-corner, .os-viewport-scrollbar-hidden.os-viewport::-webkit-scrollbar, .os-viewport-scrollbar-hidden.os-viewport::-webkit-scrollbar-corner {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 0 !important;
  height: 0 !important;
  display: none !important;
}

[data-overlayscrollbars-initialize] {
  overflow: auto;
}

html[data-overlayscrollbars], html.os-viewport-scrollbar-hidden, html.os-viewport-scrollbar-hidden > body {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
}

html[data-overlayscrollbars] > body {
  overflow: visible;
}

[data-overlayscrollbars~="host"] {
  position: relative;
}

[data-overlayscrollbars~="host"], .os-padding {
  display: flex;
  flex-flow: row !important;
}

.os-padding, .os-viewport {
  box-sizing: inherit;
  height: auto;
  width: 100%;
  z-index: 0;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
  flex: auto !important;
}

.os-viewport {
  --os-vaw: 0;
  --os-vah: 0;
}

.os-viewport.os-viewport-arrange:before {
  content: "";
  pointer-events: none;
  z-index: -1;
  min-width: 1px;
  min-height: 1px;
  width: var(--os-vaw);
  height: var(--os-vah);
  position: absolute;
}

[data-overlayscrollbars~="host"], [data-overlayscrollbars~="viewport"] {
  overflow: hidden;
}

[data-overlayscrollbars~="overflowVisible"] {
  overflow: visible;
}

[data-overlayscrollbars-overflow-x="hidden"] {
  overflow-x: hidden;
}

[data-overlayscrollbars-overflow-x="scroll"] {
  overflow-x: scroll;
}

[data-overlayscrollbars-overflow-x="hidden"] {
  overflow-y: hidden;
}

[data-overlayscrollbars-overflow-y="scroll"] {
  overflow-y: scroll;
}

.os-padding, .os-viewport {
  overflow: hidden;
}

.os-overflow-visible {
  overflow: visible;
}

.os-content {
  box-sizing: inherit;
}

[data-overlayscrollbars-grid], [data-overlayscrollbars-grid] .os-padding {
  grid-template: 1fr / 1fr;
  display: grid;
}

[data-overlayscrollbars-grid] > .os-padding, [data-overlayscrollbars-grid] > .os-viewport, [data-overlayscrollbars-grid] > .os-padding > .os-viewport {
  height: auto !important;
  width: auto !important;
}

.os-scrollbar {
  contain: strict;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s, top .3s, right .3s, bottom .3s, left .3s;
  position: absolute;
}

body > .os-scrollbar {
  z-index: 99999;
  position: fixed;
}

.os-scrollbar-transitionless {
  transition: none;
}

.os-scrollbar-track {
  position: relative;
  direction: ltr !important;
  border: none !important;
  padding: 0 !important;
}

.os-scrollbar-handle {
  position: absolute;
}

.os-scrollbar-track, .os-scrollbar-handle {
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.os-scrollbar.os-scrollbar-track-interactive .os-scrollbar-track, .os-scrollbar.os-scrollbar-handle-interactive .os-scrollbar-handle {
  pointer-events: auto;
  touch-action: none;
}

.os-scrollbar-horizontal {
  bottom: 0;
  left: 0;
}

.os-scrollbar-vertical {
  top: 0;
  right: 0;
}

.os-scrollbar-rtl.os-scrollbar-horizontal {
  right: 0;
}

.os-scrollbar-rtl.os-scrollbar-vertical {
  left: 0;
  right: auto;
}

.os-scrollbar-visible, .os-scrollbar-interaction.os-scrollbar-visible {
  opacity: 1;
  visibility: visible;
}

.os-scrollbar-auto-hidden {
  opacity: 0;
  visibility: hidden;
}

.os-scrollbar-unusable, .os-scrollbar-unusable *, .os-scrollbar-wheel, .os-scrollbar-wheel * {
  pointer-events: none !important;
}

.os-scrollbar-unusable .os-scrollbar-handle {
  opacity: 0 !important;
}

.os-scrollbar.os-scrollbar-horizontal.os-scrollbar-cornerless, .os-scrollbar.os-scrollbar-horizontal.os-scrollbar-cornerless.os-scrollbar-rtl {
  left: 0;
  right: 0;
}

.os-scrollbar.os-scrollbar-vertical.os-scrollbar-cornerless, .os-scrollbar.os-scrollbar-vertical.os-scrollbar-cornerless.os-scrollbar-rtl {
  top: 0;
  bottom: 0;
}

[data-overlayscrollbars~="updating"] > .os-scrollbar, .os-theme-none.os-scrollbar {
  display: none !important;
}

.os-theme-dark.os-scrollbar-horizontal, .os-theme-light.os-scrollbar-horizontal {
  height: 10px;
  right: 10px;
}

.os-theme-dark.os-scrollbar-vertical, .os-theme-light.os-scrollbar-vertical {
  width: 10px;
  bottom: 10px;
}

.os-theme-dark.os-scrollbar-rtl.os-scrollbar-horizontal, .os-theme-light.os-scrollbar-rtl.os-scrollbar-horizontal {
  left: 10px;
  right: 0;
}

.os-theme-dark.os-scrollbar, .os-theme-light.os-scrollbar {
  box-sizing: border-box;
  background: none;
  padding: 2px;
}

.os-theme-dark.os-scrollbar-unusable, .os-theme-light.os-scrollbar-unusable, .os-theme-dark.os-scrollbar > .os-scrollbar-track, .os-theme-light.os-scrollbar > .os-scrollbar-track {
  background: none;
}

.os-theme-dark.os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-light.os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
  min-width: 30px;
}

.os-theme-dark.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-light.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
  min-height: 30px;
}

.os-theme-dark.os-scrollbar-transition > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-light.os-scrollbar-transition > .os-scrollbar-track > .os-scrollbar-handle {
  transition: background-color .3s;
}

.os-theme-dark.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-light.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-dark.os-scrollbar > .os-scrollbar-track, .os-theme-light.os-scrollbar > .os-scrollbar-track {
  border-radius: 10px;
}

.os-theme-dark.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: #0006;
}

.os-theme-light.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: #fff6;
}

.os-theme-dark.os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: #0000008c;
}

.os-theme-light.os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: #ffffff8c;
}

.os-theme-dark.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: #000000b3;
}

.os-theme-light.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: #ffffffb3;
}

.os-theme-dark.os-scrollbar-horizontal .os-scrollbar-handle:before, .os-theme-dark.os-scrollbar-vertical .os-scrollbar-handle:before, .os-theme-light.os-scrollbar-horizontal .os-scrollbar-handle:before, .os-theme-light.os-scrollbar-vertical .os-scrollbar-handle:before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
}

.os-theme-dark.os-host-scrollbar-hidden > .os-scrollbar-handle:before {
  display: none;
}

.os-theme-dark.os-scrollbar-horizontal .os-scrollbar-handle:before, .os-theme-light.os-scrollbar-horizontal .os-scrollbar-handle:before {
  top: -6px;
  bottom: -2px;
}

.os-theme-dark.os-scrollbar-vertical .os-scrollbar-handle:before, .os-theme-light.os-scrollbar-vertical .os-scrollbar-handle:before {
  left: -6px;
  right: -2px;
}

.os-theme-dark.os-scrollbar-rtl.os-scrollbar-vertical .os-scrollbar-handle:before, .os-theme-light.os-scrollbar-rtl.os-scrollbar-vertical .os-scrollbar-handle:before {
  left: -2px;
  right: -6px;
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

:root {
  --font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-1: 600 32px / 40px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-2: 600 28px / 36px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-3: 600 24px / 32px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-1: 600 20px / 28px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-2: 600 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-3: 600 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-1: 500 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-2: 500 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-3: 500 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-1: 400 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-2: 400 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-3: 400 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-section-head-1: 700 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-section-head-2: 700 10px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
}

body {
  background: var(--color-base);
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

input {
  border: none;
  outline: none;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar:horizontal {
  height: 16px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-neutral-variant-80);
  min-height: 40px;
  background-clip: content-box;
  border: 4px solid #0000;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  background: none;
}

.appInsightsWrapper {
  width: 100%;
  height: 100%;
  flex: 1;
}

.os-scrollbar.os-theme-dark.os-scrollbar-vertical, .os-scrollbar.os-theme-light.os-scrollbar-vertical {
  width: 12px;
  padding: 8px 2px;
}

.os-scrollbar.os-theme-dark.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle, .os-scrollbar.os-theme-light.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
  background: var(--color-neutral-variant-80);
  min-height: 40px;
}

.os-scrollbar.os-theme-dark.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover, .os-scrollbar.os-theme-light.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover {
  background: var(--color-neutral-variant-80);
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-cyrillic-ext-400-normal.dcb520ee.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-cyrillic-400-normal.638e826e.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-greek-400-normal.dd843e41.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-vietnamese-400-normal.306e7635.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-latin-ext-400-normal.d9409874.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-latin-400-normal.cf1eee5f.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@keyframes f917Mq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes f917Mq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.f917Mq_ring {
  animation: 1s ease-in-out infinite f917Mq_rotating;
}

.f917Mq_daisy {
  animation: 1s steps(12, end) infinite f917Mq_rotating;
}

._2eodma_light {
  --color-all-0: #000;
  --color-all-100: #fff;
  --color-primary-10: #190064;
  --color-primary-20: #2d009d;
  --color-primary-30: #4300da;
  --color-primary-40: #5d34f2;
  --color-primary-50: #7958ff;
  --color-primary-60: #947dff;
  --color-primary-70: #af9eff;
  --color-primary-80: #cabeff;
  --color-primary-90: #e6deff;
  --color-primary-95: #f5eeff;
  --color-primary-99: #fffbff;
  --color-secondary-10: #201c00;
  --color-secondary-20: #373100;
  --color-secondary-30: #4f4700;
  --color-secondary-40: #695f00;
  --color-secondary-50: #847900;
  --color-secondary-60: #9f930d;
  --color-secondary-70: #baad2d;
  --color-secondary-80: #d7c947;
  --color-secondary-90: #f4e560;
  --color-secondary-95: #fff480;
  --color-secondary-99: #fffbf7;
  --color-tertiary-10: #350041;
  --color-tertiary-20: #560068;
  --color-tertiary-30: #7b0093;
  --color-tertiary-40: #9927af;
  --color-tertiary-50: #b545ca;
  --color-tertiary-60: #d361e7;
  --color-tertiary-70: #f07eff;
  --color-tertiary-80: #faabff;
  --color-tertiary-90: #ffd5ff;
  --color-tertiary-95: #ffeafe;
  --color-tertiary-99: #fcfcfc;
  --color-error-10: #410001;
  --color-error-20: #680003;
  --color-error-30: #930006;
  --color-error-40: #ba1b1b;
  --color-error-50: #dd3730;
  --color-error-60: #ff5449;
  --color-error-70: #ff897a;
  --color-error-80: #ffb4a9;
  --color-error-90: #ffdad4;
  --color-error-95: #ffede9;
  --color-error-99: #fcfcfc;
  --color-neutral-10: #191c1d;
  --color-neutral-20: #2d3132;
  --color-neutral-30: #444748;
  --color-neutral-40: #5c5f60;
  --color-neutral-50: #747778;
  --color-neutral-60: #8e9192;
  --color-neutral-70: #a9acac;
  --color-neutral-80: #c4c7c7;
  --color-neutral-90: #e0e3e3;
  --color-neutral-95: #eff1f1;
  --color-neutral-99: #f7f8f8;
  --color-neutral-variant-10: #1b1b22;
  --color-neutral-variant-20: #302f38;
  --color-neutral-variant-30: #47464e;
  --color-neutral-variant-40: #5f5d67;
  --color-neutral-variant-50: #78767f;
  --color-neutral-variant-60: #928f9a;
  --color-neutral-variant-70: #adaab4;
  --color-neutral-variant-80: #c9c5d0;
  --color-neutral-variant-90: #e5e1ec;
  --color-neutral-variant-95: #f3effa;
  --color-neutral-variant-99: #fffbff;
  --color-success-10: #002106;
  --color-success-20: #00390b;
  --color-success-30: #005314;
  --color-success-40: #116d26;
  --color-success-50: #32873d;
  --color-success-60: #4ea254;
  --color-success-70: #68be6c;
  --color-success-80: #83da85;
  --color-success-90: #9fe79f;
  --color-success-95: #bdefbd;
  --color-success-99: #ebf9eb;
  --color-alert-10: #2b1700;
  --color-alert-20: #472a00;
  --color-alert-30: #663e00;
  --color-alert-40: #865300;
  --color-alert-50: #a76900;
  --color-alert-60: #ca8000;
  --color-alert-70: #eb9918;
  --color-alert-80: #ffb95a;
  --color-alert-90: #ffddb5;
  --color-alert-95: #ffeedc;
  --color-alert-99: #fff5eb;
  --color-primary: var(--color-primary-40);
  --color-on-primary: var(--color-all-100);
  --color-primary-container: var(--color-primary-90);
  --color-on-primary-container: var(--color-primary-10);
  --color-secondary: var(--color-secondary-40);
  --color-on-secondary: var(--color-all-100);
  --color-secondary-container: var(--color-secondary-30);
  --color-on-secondary-container: var(--color-secondary-10);
  --color-tertiary: var(--color-tertiary-40);
  --color-on-tertiary: var(--color-all-100);
  --color-tertiary-container: var(--color-tertiary-90);
  --color-on-tertiary-container: var(--color-tertiary-10);
  --color-error: var(--color-error-40);
  --color-on-error: var(--color-all-100);
  --color-error-container: var(--color-error-90);
  --color-on-error-container: var(--color-error-10);
  --color-alert-container: var(--color-alert-99);
  --color-background: var(--color-neutral-99);
  --color-on-background: var(--color-neutral-10);
  --color-surface: var(--color-neutral-99);
  --color-surface-1: #ecebf6;
  --color-surface-2: #e8e6f6;
  --color-surface-3: #e3e0f6;
  --color-surface-4: #dfdaf5;
  --color-surface-5: #dcd6f5;
  --color-on-surface: var(--color-neutral-10);
  --color-surface-variant: var(--color-neutral-variant-90);
  --color-on-surface-variant: var(--color-neutral-variant-30);
  --color-outline: var(--color-neutral-variant-50);
  --color-primary-hover: var(--color-primary-50);
  --color-primary-pressed: var(--color-primary-30);
  --color-danger-hover: var(--color-error-50);
  --color-danger-pressed: var(--color-error-30);
  --color-text: var(--color-neutral-10);
  --color-text-link: var(--color-primary-40);
  --color-text-secondary: var(--color-neutral-50);
  --color-placeholder: var(--color-neutral-70);
  --color-border: var(--color-neutral-80);
  --color-divider: var(--color-neutral-90);
  --color-disabled: var(--color-neutral-80);
  --color-base: var(--color-surface-1);
  --color-layer-1: var(--color-all-100);
  --color-layer-2: var(--color-neutral-95);
  --color-float: var(--color-all-100);
  --color-layer-light: var(--color-neutral-99);
  --color-inverse-surface: var(--color-neutral-20);
  --color-inverse-on-surface: var(--color-neutral-95);
  --color-inverse-primary: var(--color-primary-50);
  --color-shadow: var(--color-all-100);
  --color-white: #fff;
  --color-button-icon: var(--color-primary-80);
  --color-icon-background: var(--color-neutral-variant-95);
  --color-hover: #191c1d14;
  --color-pressed: #191c1d1f;
  --color-focused: #191c1d29;
  --color-hover-variant: #5d34f214;
  --color-pressed-variant: #5d34f21f;
  --color-focused-variant: #5d34f229;
  --shadow-1: 0 4px 8px #00000014;
  --shadow-2: 0 4px 12px #0000001f;
  --shadow-2-reversed: 0 -4px 12px #0000001f;
  --shadow-3: 0 4px 16px #0003;
  --color-checkbox-disabled-background: var(--color-neutral-95);
  --color-checkbox-checked-disabled-background: var(--color-primary-80);
  --color-danger-toast-background: var(--color-error-95);
  --color-danger-focused: #ba1b1b29;
  --color-tooltip-background: #34353f;
  --color-tooltip-text: var(--color-neutral-99);
  --color-overlay: #0000004d;
  --color-drawer-overlay: #0006;
  --color-guide-dropdown-background: var(--color-white);
  --color-guide-dropdown-border: var(--color-border);
  --color-skeleton-shimmer-rgb: 255, 255, 255;
}

._2eodma_dark {
  --color-all-0: #fff;
  --color-all-100: #000;
  --color-primary-10: #fffbff;
  --color-primary-20: #f5eeff;
  --color-primary-30: #e6deff;
  --color-primary-40: #cabeff;
  --color-primary-50: #af9eff;
  --color-primary-60: #947dff;
  --color-primary-70: #7958ff;
  --color-primary-80: #5d34f2;
  --color-primary-90: #4300da;
  --color-primary-95: #2d009d;
  --color-primary-99: #190064;
  --color-secondary-10: #fffbf7;
  --color-secondary-20: #fff480;
  --color-secondary-30: #f4e560;
  --color-secondary-40: #d7c947;
  --color-secondary-50: #baad2d;
  --color-secondary-60: #9f930d;
  --color-secondary-70: #847900;
  --color-secondary-80: #695f00;
  --color-secondary-90: #4f4700;
  --color-secondary-95: #373100;
  --color-secondary-99: #201c00;
  --color-tertiary-10: #fcfcfc;
  --color-tertiary-20: #ffeafe;
  --color-tertiary-30: #ffd5ff;
  --color-tertiary-40: #faabff;
  --color-tertiary-50: #f07eff;
  --color-tertiary-60: #d361e7;
  --color-tertiary-70: #b545ca;
  --color-tertiary-80: #9927af;
  --color-tertiary-90: #7b0093;
  --color-tertiary-95: #560068;
  --color-tertiary-99: #350041;
  --color-error-10: #fcfcfc;
  --color-error-20: #ffede9;
  --color-error-30: #ffdad4;
  --color-error-40: #ffb4a9;
  --color-error-50: #ff897a;
  --color-error-60: #ff5449;
  --color-error-70: #dd3730;
  --color-error-80: #ba1b1b;
  --color-error-90: #930006;
  --color-error-95: #680003;
  --color-error-99: #410001;
  --color-neutral-10: #f7f8f8;
  --color-neutral-20: #eff1f1;
  --color-neutral-30: #e0e3e3;
  --color-neutral-40: #c4c7c7;
  --color-neutral-50: #a9acac;
  --color-neutral-60: #8e9192;
  --color-neutral-70: #747778;
  --color-neutral-80: #5c5f60;
  --color-neutral-90: #444748;
  --color-neutral-95: #2d3132;
  --color-neutral-99: #191c1d;
  --color-neutral-variant-10: #fffbff;
  --color-neutral-variant-20: #f3effa;
  --color-neutral-variant-30: #e5e1ec;
  --color-neutral-variant-40: #c9c5d0;
  --color-neutral-variant-50: #adaab4;
  --color-neutral-variant-60: #928f9a;
  --color-neutral-variant-70: #78767f;
  --color-neutral-variant-80: #5f5d67;
  --color-neutral-variant-90: #47464e;
  --color-neutral-variant-95: #302f38;
  --color-neutral-variant-99: #1b1b22;
  --color-success-10: #ebf9eb;
  --color-success-20: #bdefbd;
  --color-success-30: #9fe79f;
  --color-success-40: #83da85;
  --color-success-50: #68be6c;
  --color-success-60: #4ea254;
  --color-success-70: #32873d;
  --color-success-80: #116d26;
  --color-success-90: #005314;
  --color-success-95: #00390b;
  --color-success-99: #002106;
  --color-alert-10: #fff5eb;
  --color-alert-20: #ffeedc;
  --color-alert-30: #ffddb5;
  --color-alert-40: #ffb95a;
  --color-alert-50: #eb9918;
  --color-alert-60: #ca8000;
  --color-alert-70: #a76900;
  --color-alert-80: #865300;
  --color-alert-90: #663e00;
  --color-alert-95: #472a00;
  --color-alert-99: #2b1700;
  --color-primary: var(--color-primary-70);
  --color-on-primary: var(--color-all-0);
  --color-primary-container: var(--color-primary-30);
  --color-on-primary-container: var(--color-primary-30);
  --color-secondary: var(--color-secondary-70);
  --color-on-secondary: var(--color-all-0);
  --color-secondary-container: var(--color-secondary-90);
  --color-on-secondary-container: var(--color-secondary-30);
  --color-tertiary: var(--color-tertiary-70);
  --color-on-tertiary: var(--color-all-0);
  --color-tertiary-container: var(--color-tertiary-90);
  --color-on-tertiary-container: var(--color-tertiary-30);
  --color-error: var(--color-error-70);
  --color-on-error: var(--color-all-0);
  --color-error-container: var(--color-error-90);
  --color-on-error-container: var(--color-error-30);
  --color-alert-container: var(--color-alert-90);
  --color-background: var(--color-neutral-99);
  --color-on-background: var(--color-neutral-10);
  --color-surface: var(--color-neutral-99);
  --color-surface-1: #25272b;
  --color-surface-2: #2a2c32;
  --color-surface-3: #2f3039;
  --color-surface-4: #34353f;
  --color-surface-5: #383844;
  --color-on-surface: var(--color-neutral-10);
  --color-surface-variant: var(--color-neutral-variant-90);
  --color-on-surface-variant: var(--color-neutral-variant-40);
  --color-outline: var(--color-neutral-variant-60);
  --color-primary-hover: var(--color-primary-60);
  --color-primary-pressed: var(--color-primary-80);
  --color-danger-hover: var(--color-error-60);
  --color-danger-pressed: var(--color-error-80);
  --color-text: var(--color-neutral-10);
  --color-text-link: var(--color-primary-40);
  --color-text-secondary: var(--color-neutral-50);
  --color-placeholder: var(--color-neutral-70);
  --color-border: var(--color-neutral-80);
  --color-divider: var(--color-neutral-90);
  --color-disabled: var(--color-neutral-80);
  --color-base: var(--color-surface);
  --color-layer-1: var(--color-surface-2);
  --color-layer-2: var(--color-surface-4);
  --color-float: var(--color-surface-4);
  --color-layer-light: var(--color-surface-4);
  --color-inverse-surface: var(--color-neutral-20);
  --color-inverse-on-surface: var(--color-neutral-95);
  --color-inverse-primary: var(--color-primary-80);
  --color-shadow: var(--color-all-100);
  --color-white: #fff;
  --color-button-icon: var(--color-primary-20);
  --color-icon-background: #3a3b59;
  --color-hover: #f7f8f814;
  --color-pressed: #f7f8f81f;
  --color-focused: #f7f8f829;
  --color-hover-variant: #cabeff14;
  --color-pressed-variant: #cabeff1f;
  --color-focused-variant: #cabeff29;
  --shadow-1: 0 4px 8px #00000014;
  --shadow-2: 0 4px 12px #0000001f;
  --shadow-2-reversed: 0 -4px 12px #0000001f;
  --shadow-3: 0 4px 16px #0003;
  --color-checkbox-disabled-background: #f7f8f814;
  --color-checkbox-checked-disabled-background: var(--color-primary-40);
  --color-danger-toast-background: var(--color-error-99);
  --color-danger-focused: #ffb4a929;
  --color-tooltip-background: var(--color-surface-4);
  --color-tooltip-text: var(--color-neutral-10);
  --color-overlay: #0000004d;
  --color-drawer-overlay: #0009;
  --color-guide-dropdown-background: var(--color-neutral-variant-80);
  --color-guide-dropdown-border: var(--color-neutral-variant-70);
  --color-skeleton-shimmer-rgb: 42, 44, 50;
}

@keyframes FA5ZUG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FA5ZUG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FA5ZUG_container {
  height: 100vh;
  color: var(--color-text);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.FA5ZUG_container svg {
  margin-bottom: 16px;
}

.IeYpga_app {
  position: absolute;
  inset: 0;
}

@keyframes Mn-v3W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Mn-v3W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Mn-v3W_container {
  background-color: var(--color-surface-1);
  color: var(--color-text);
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  display: flex;
  overflow-y: auto;
}

.Mn-v3W_container > * {
  margin-top: 24px;
}

.Mn-v3W_container > svg {
  height: 256px;
  width: 256px;
  flex-shrink: 0;
  margin-top: 120px;
}

.Mn-v3W_container label {
  font: var(--font-title-1);
}

.Mn-v3W_container .Mn-v3W_summary {
  max-width: 470px;
  font: var(--font-body-2);
  text-align: center;
  align-items: center;
  margin-top: 16px;
  display: flex;
}

.Mn-v3W_container .Mn-v3W_summary .Mn-v3W_expander {
  color: var(--color-primary);
  cursor: pointer;
  align-items: center;
  margin-left: 8px;
  display: inline-flex;
}

.Mn-v3W_container .Mn-v3W_summary svg {
  width: 20px;
  height: 20px;
  color: var(--color-primary);
}

.Mn-v3W_container .Mn-v3W_details {
  width: 470px;
  background-color: var(--color-layer-1);
  font: var(--font-body-2);
  white-space: pre-wrap;
  word-break: break-all;
  border-radius: 16px;
  padding: 24px;
}

@keyframes SfX6tW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes SfX6tW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SfX6tW_button {
  font: var(--font-label-2);
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  border-radius: 8px;
  outline: none;
  justify-content: center;
  align-items: center;
  transition: background-color .2s ease-in-out;
  display: flex;
  position: relative;
}

.SfX6tW_button.SfX6tW_withIcon {
  display: inline-flex;
}

.SfX6tW_button.SfX6tW_loading {
  pointer-events: none;
  opacity: .6;
}

.SfX6tW_button.SfX6tW_loading .SfX6tW_spinner {
  position: absolute;
}

.SfX6tW_button.SfX6tW_loading .SfX6tW_spinner ~ span {
  visibility: hidden;
}

.SfX6tW_button:not(:disabled) {
  cursor: pointer;
}

.SfX6tW_button:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.SfX6tW_button .SfX6tW_icon {
  align-items: center;
  display: flex;
}

.SfX6tW_button .SfX6tW_icon:not(:last-child) {
  margin-right: 8px;
}

.SfX6tW_button .SfX6tW_trailingIcon {
  align-items: center;
  display: flex;
}

.SfX6tW_button .SfX6tW_trailingIcon:not(:first-child) {
  margin-left: 8px;
}

.SfX6tW_button.SfX6tW_small {
  height: 30px;
  padding: 0 12px;
}

.SfX6tW_button.SfX6tW_small.SfX6tW_text {
  height: 24px;
}

.SfX6tW_button.SfX6tW_small .SfX6tW_icon:not(:last-child) {
  margin-right: 4px;
}

.SfX6tW_button.SfX6tW_small .SfX6tW_trailingIcon:not(:first-child) {
  margin-left: 4px;
}

.SfX6tW_button.SfX6tW_medium {
  height: 36px;
  padding: 0 16px;
}

.SfX6tW_button.SfX6tW_medium.SfX6tW_text {
  font: var(--font-label-1);
  height: 28px;
}

.SfX6tW_button.SfX6tW_large {
  height: 44px;
  padding: 0 24px;
}

.SfX6tW_button.SfX6tW_large.SfX6tW_text {
  font: var(--font-label-1);
  height: 28px;
}

.SfX6tW_button.SfX6tW_default {
  background: var(--color-layer-1);
  color: var(--color-text);
  border-color: var(--color-border);
  border-style: solid;
  border-width: 1px;
}

.SfX6tW_button.SfX6tW_default:disabled {
  border-color: var(--color-border);
  color: var(--color-neutral-70);
}

.SfX6tW_button.SfX6tW_default:focus-visible {
  outline: 3px solid var(--color-focused);
}

.SfX6tW_button.SfX6tW_default:active {
  background: var(--color-pressed);
}

.SfX6tW_button.SfX6tW_default:not(:disabled):not(:active):hover {
  background: var(--color-hover);
}

.SfX6tW_button.SfX6tW_branding {
  color: var(--color-on-primary);
  background: linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.SfX6tW_button.SfX6tW_branding:focus-visible {
  background: linear-gradient(0deg, #0003, #0003), linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.SfX6tW_button.SfX6tW_branding:active {
  background: linear-gradient(0deg, #0003, #0003), linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.SfX6tW_button.SfX6tW_branding:not(:disabled):not(:active):hover {
  background: linear-gradient(0deg, #fff3, #fff3), linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.SfX6tW_button.SfX6tW_primary {
  background: var(--color-primary);
  color: var(--color-on-primary);
}

.SfX6tW_button.SfX6tW_primary:disabled {
  background: var(--color-inverse-on-surface);
  color: var(--color-neutral-70);
}

.SfX6tW_button.SfX6tW_primary:focus-visible {
  outline: 3px solid var(--color-focused-variant);
}

.SfX6tW_button.SfX6tW_primary:active {
  background: var(--color-primary-pressed);
}

.SfX6tW_button.SfX6tW_primary:not(:disabled):not(:active):hover {
  background: var(--color-primary-hover);
}

.SfX6tW_button.SfX6tW_danger {
  background: var(--color-error);
  color: var(--color-on-primary);
}

.SfX6tW_button.SfX6tW_danger:disabled {
  background: var(--color-inverse-on-surface);
  color: var(--color-neutral-70);
}

.SfX6tW_button.SfX6tW_danger:focus-visible {
  outline: 3px solid var(--color-danger-focused);
}

.SfX6tW_button.SfX6tW_danger:active {
  background: var(--color-danger-pressed);
}

.SfX6tW_button.SfX6tW_danger:not(:disabled):not(:active):hover {
  background: var(--color-danger-hover);
}

.SfX6tW_button.SfX6tW_outline {
  background: var(--color-layer-1);
  border-color: var(--color-text-link);
  color: var(--color-text-link);
  border-style: solid;
  border-width: 1px;
}

.SfX6tW_button.SfX6tW_outline:disabled {
  border-color: var(--color-neutral-70);
  color: var(--color-neutral-70);
}

.SfX6tW_button.SfX6tW_outline:focus-visible {
  outline: 3px solid var(--color-focused-variant);
}

.SfX6tW_button.SfX6tW_outline:active {
  background: var(--color-pressed-variant);
}

.SfX6tW_button.SfX6tW_outline:not(:disabled):not(:active):hover {
  background: var(--color-hover-variant);
}

.SfX6tW_button.SfX6tW_text {
  border-color: none;
  font: var(--font-label-2);
  color: var(--color-text-link);
  background: none;
  border-radius: 4px;
  padding: 2px 4px;
}

.SfX6tW_button.SfX6tW_text:disabled {
  color: var(--color-disabled);
}

.SfX6tW_button.SfX6tW_text:focus-visible {
  outline: 2px solid var(--color-focused-variant);
}

.SfX6tW_button.SfX6tW_text:not(:disabled):hover {
  background-color: var(--color-hover-variant);
}

.SfX6tW_button.SfX6tW_violet {
  background: var(--color-layer-1);
  border: 1px solid var(--color-surface-5);
  position: relative;
  overflow: hidden;
}

.SfX6tW_button.SfX6tW_violet .SfX6tW_icon, .SfX6tW_button.SfX6tW_violet .SfX6tW_trailingIcon {
  color: var(--color-primary);
}

.SfX6tW_button.SfX6tW_violet:disabled {
  color: var(--color-disabled);
}

.SfX6tW_button.SfX6tW_violet:disabled .SfX6tW_icon, .SfX6tW_button.SfX6tW_violet:disabled .SfX6tW_trailingIcon {
  color: var(--color-primary-80);
}

.SfX6tW_button.SfX6tW_violet:focus-visible {
  border: 2px solid var(--color-primary-40);
  outline: 4px solid var(--color-focused-variant);
}

.SfX6tW_button.SfX6tW_violet:not(:disabled):hover {
  background-color: var(--color-hover-variant);
}

.SfX6tW_button.SfX6tW_violet:not(:disabled):hover:before {
  content: "";
  background-color: var(--color-layer-1);
  z-index: -1;
  position: absolute;
  inset: 0;
}

@keyframes S7dkFG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes S7dkFG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.S7dkFG_retryButton {
  flex-shrink: 0;
  margin-top: 24px;
}

@keyframes wxx1SG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wxx1SG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wxx1SG_wrapper {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes jPkTZa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes jPkTZa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.jPkTZa_card {
  background: var(--color-layer-1);
  border-radius: 16px;
  padding: 24px;
}

@keyframes k1s4KG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes k1s4KG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

:root {
  --font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-1: 600 32px / 40px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-2: 600 28px / 36px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-3: 600 24px / 32px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-1: 600 20px / 28px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-2: 600 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-3: 600 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-1: 500 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-2: 500 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-3: 500 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-1: 400 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-2: 400 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-3: 400 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-section-head-1: 700 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-section-head-2: 700 10px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
}

.k1s4KG_container .k1s4KG_card {
  background: var(--color-layer-1);
  width: 640px;
  height: 640px;
  text-align: center;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k1s4KG_container .k1s4KG_card > svg {
  width: 120px;
  height: 120px;
  margin-top: 100px;
}

.k1s4KG_container .k1s4KG_card .k1s4KG_title {
  color: var(--color-neutral-10);
  font: var(--font-title-2);
  margin-top: 64px;
}

.k1s4KG_container .k1s4KG_card .k1s4KG_message {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 12px;
  padding: 0 32px;
}

@media (prefers-color-scheme: light) {
  body {
    --color-all-0: #000;
    --color-all-100: #fff;
    --color-primary-10: #190064;
    --color-primary-20: #2d009d;
    --color-primary-30: #4300da;
    --color-primary-40: #5d34f2;
    --color-primary-50: #7958ff;
    --color-primary-60: #947dff;
    --color-primary-70: #af9eff;
    --color-primary-80: #cabeff;
    --color-primary-90: #e6deff;
    --color-primary-95: #f5eeff;
    --color-primary-99: #fffbff;
    --color-secondary-10: #201c00;
    --color-secondary-20: #373100;
    --color-secondary-30: #4f4700;
    --color-secondary-40: #695f00;
    --color-secondary-50: #847900;
    --color-secondary-60: #9f930d;
    --color-secondary-70: #baad2d;
    --color-secondary-80: #d7c947;
    --color-secondary-90: #f4e560;
    --color-secondary-95: #fff480;
    --color-secondary-99: #fffbf7;
    --color-tertiary-10: #350041;
    --color-tertiary-20: #560068;
    --color-tertiary-30: #7b0093;
    --color-tertiary-40: #9927af;
    --color-tertiary-50: #b545ca;
    --color-tertiary-60: #d361e7;
    --color-tertiary-70: #f07eff;
    --color-tertiary-80: #faabff;
    --color-tertiary-90: #ffd5ff;
    --color-tertiary-95: #ffeafe;
    --color-tertiary-99: #fcfcfc;
    --color-error-10: #410001;
    --color-error-20: #680003;
    --color-error-30: #930006;
    --color-error-40: #ba1b1b;
    --color-error-50: #dd3730;
    --color-error-60: #ff5449;
    --color-error-70: #ff897a;
    --color-error-80: #ffb4a9;
    --color-error-90: #ffdad4;
    --color-error-95: #ffede9;
    --color-error-99: #fcfcfc;
    --color-neutral-10: #191c1d;
    --color-neutral-20: #2d3132;
    --color-neutral-30: #444748;
    --color-neutral-40: #5c5f60;
    --color-neutral-50: #747778;
    --color-neutral-60: #8e9192;
    --color-neutral-70: #a9acac;
    --color-neutral-80: #c4c7c7;
    --color-neutral-90: #e0e3e3;
    --color-neutral-95: #eff1f1;
    --color-neutral-99: #f7f8f8;
    --color-neutral-variant-10: #1b1b22;
    --color-neutral-variant-20: #302f38;
    --color-neutral-variant-30: #47464e;
    --color-neutral-variant-40: #5f5d67;
    --color-neutral-variant-50: #78767f;
    --color-neutral-variant-60: #928f9a;
    --color-neutral-variant-70: #adaab4;
    --color-neutral-variant-80: #c9c5d0;
    --color-neutral-variant-90: #e5e1ec;
    --color-neutral-variant-95: #f3effa;
    --color-neutral-variant-99: #fffbff;
    --color-success-10: #002106;
    --color-success-20: #00390b;
    --color-success-30: #005314;
    --color-success-40: #116d26;
    --color-success-50: #32873d;
    --color-success-60: #4ea254;
    --color-success-70: #68be6c;
    --color-success-80: #83da85;
    --color-success-90: #9fe79f;
    --color-success-95: #bdefbd;
    --color-success-99: #ebf9eb;
    --color-alert-10: #2b1700;
    --color-alert-20: #472a00;
    --color-alert-30: #663e00;
    --color-alert-40: #865300;
    --color-alert-50: #a76900;
    --color-alert-60: #ca8000;
    --color-alert-70: #eb9918;
    --color-alert-80: #ffb95a;
    --color-alert-90: #ffddb5;
    --color-alert-95: #ffeedc;
    --color-alert-99: #fff5eb;
    --color-primary: var(--color-primary-40);
    --color-on-primary: var(--color-all-100);
    --color-primary-container: var(--color-primary-90);
    --color-on-primary-container: var(--color-primary-10);
    --color-secondary: var(--color-secondary-40);
    --color-on-secondary: var(--color-all-100);
    --color-secondary-container: var(--color-secondary-30);
    --color-on-secondary-container: var(--color-secondary-10);
    --color-tertiary: var(--color-tertiary-40);
    --color-on-tertiary: var(--color-all-100);
    --color-tertiary-container: var(--color-tertiary-90);
    --color-on-tertiary-container: var(--color-tertiary-10);
    --color-error: var(--color-error-40);
    --color-on-error: var(--color-all-100);
    --color-error-container: var(--color-error-90);
    --color-on-error-container: var(--color-error-10);
    --color-alert-container: var(--color-alert-99);
    --color-background: var(--color-neutral-99);
    --color-on-background: var(--color-neutral-10);
    --color-surface: var(--color-neutral-99);
    --color-surface-1: #ecebf6;
    --color-surface-2: #e8e6f6;
    --color-surface-3: #e3e0f6;
    --color-surface-4: #dfdaf5;
    --color-surface-5: #dcd6f5;
    --color-on-surface: var(--color-neutral-10);
    --color-surface-variant: var(--color-neutral-variant-90);
    --color-on-surface-variant: var(--color-neutral-variant-30);
    --color-outline: var(--color-neutral-variant-50);
    --color-primary-hover: var(--color-primary-50);
    --color-primary-pressed: var(--color-primary-30);
    --color-danger-hover: var(--color-error-50);
    --color-danger-pressed: var(--color-error-30);
    --color-text: var(--color-neutral-10);
    --color-text-link: var(--color-primary-40);
    --color-text-secondary: var(--color-neutral-50);
    --color-placeholder: var(--color-neutral-70);
    --color-border: var(--color-neutral-80);
    --color-divider: var(--color-neutral-90);
    --color-disabled: var(--color-neutral-80);
    --color-base: var(--color-surface-1);
    --color-layer-1: var(--color-all-100);
    --color-layer-2: var(--color-neutral-95);
    --color-float: var(--color-all-100);
    --color-layer-light: var(--color-neutral-99);
    --color-inverse-surface: var(--color-neutral-20);
    --color-inverse-on-surface: var(--color-neutral-95);
    --color-inverse-primary: var(--color-primary-50);
    --color-shadow: var(--color-all-100);
    --color-white: #fff;
    --color-button-icon: var(--color-primary-80);
    --color-icon-background: var(--color-neutral-variant-95);
    --color-hover: #191c1d14;
    --color-pressed: #191c1d1f;
    --color-focused: #191c1d29;
    --color-hover-variant: #5d34f214;
    --color-pressed-variant: #5d34f21f;
    --color-focused-variant: #5d34f229;
    --shadow-1: 0 4px 8px #00000014;
    --shadow-2: 0 4px 12px #0000001f;
    --shadow-2-reversed: 0 -4px 12px #0000001f;
    --shadow-3: 0 4px 16px #0003;
    --color-checkbox-disabled-background: var(--color-neutral-95);
    --color-checkbox-checked-disabled-background: var(--color-primary-80);
    --color-danger-toast-background: var(--color-error-95);
    --color-danger-focused: #ba1b1b29;
    --color-tooltip-background: #34353f;
    --color-tooltip-text: var(--color-neutral-99);
    --color-overlay: #0000004d;
    --color-drawer-overlay: #0006;
    --color-guide-dropdown-background: var(--color-white);
    --color-guide-dropdown-border: var(--color-border);
    --color-skeleton-shimmer-rgb: 255, 255, 255;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    --color-all-0: #fff;
    --color-all-100: #000;
    --color-primary-10: #fffbff;
    --color-primary-20: #f5eeff;
    --color-primary-30: #e6deff;
    --color-primary-40: #cabeff;
    --color-primary-50: #af9eff;
    --color-primary-60: #947dff;
    --color-primary-70: #7958ff;
    --color-primary-80: #5d34f2;
    --color-primary-90: #4300da;
    --color-primary-95: #2d009d;
    --color-primary-99: #190064;
    --color-secondary-10: #fffbf7;
    --color-secondary-20: #fff480;
    --color-secondary-30: #f4e560;
    --color-secondary-40: #d7c947;
    --color-secondary-50: #baad2d;
    --color-secondary-60: #9f930d;
    --color-secondary-70: #847900;
    --color-secondary-80: #695f00;
    --color-secondary-90: #4f4700;
    --color-secondary-95: #373100;
    --color-secondary-99: #201c00;
    --color-tertiary-10: #fcfcfc;
    --color-tertiary-20: #ffeafe;
    --color-tertiary-30: #ffd5ff;
    --color-tertiary-40: #faabff;
    --color-tertiary-50: #f07eff;
    --color-tertiary-60: #d361e7;
    --color-tertiary-70: #b545ca;
    --color-tertiary-80: #9927af;
    --color-tertiary-90: #7b0093;
    --color-tertiary-95: #560068;
    --color-tertiary-99: #350041;
    --color-error-10: #fcfcfc;
    --color-error-20: #ffede9;
    --color-error-30: #ffdad4;
    --color-error-40: #ffb4a9;
    --color-error-50: #ff897a;
    --color-error-60: #ff5449;
    --color-error-70: #dd3730;
    --color-error-80: #ba1b1b;
    --color-error-90: #930006;
    --color-error-95: #680003;
    --color-error-99: #410001;
    --color-neutral-10: #f7f8f8;
    --color-neutral-20: #eff1f1;
    --color-neutral-30: #e0e3e3;
    --color-neutral-40: #c4c7c7;
    --color-neutral-50: #a9acac;
    --color-neutral-60: #8e9192;
    --color-neutral-70: #747778;
    --color-neutral-80: #5c5f60;
    --color-neutral-90: #444748;
    --color-neutral-95: #2d3132;
    --color-neutral-99: #191c1d;
    --color-neutral-variant-10: #fffbff;
    --color-neutral-variant-20: #f3effa;
    --color-neutral-variant-30: #e5e1ec;
    --color-neutral-variant-40: #c9c5d0;
    --color-neutral-variant-50: #adaab4;
    --color-neutral-variant-60: #928f9a;
    --color-neutral-variant-70: #78767f;
    --color-neutral-variant-80: #5f5d67;
    --color-neutral-variant-90: #47464e;
    --color-neutral-variant-95: #302f38;
    --color-neutral-variant-99: #1b1b22;
    --color-success-10: #ebf9eb;
    --color-success-20: #bdefbd;
    --color-success-30: #9fe79f;
    --color-success-40: #83da85;
    --color-success-50: #68be6c;
    --color-success-60: #4ea254;
    --color-success-70: #32873d;
    --color-success-80: #116d26;
    --color-success-90: #005314;
    --color-success-95: #00390b;
    --color-success-99: #002106;
    --color-alert-10: #fff5eb;
    --color-alert-20: #ffeedc;
    --color-alert-30: #ffddb5;
    --color-alert-40: #ffb95a;
    --color-alert-50: #eb9918;
    --color-alert-60: #ca8000;
    --color-alert-70: #a76900;
    --color-alert-80: #865300;
    --color-alert-90: #663e00;
    --color-alert-95: #472a00;
    --color-alert-99: #2b1700;
    --color-primary: var(--color-primary-70);
    --color-on-primary: var(--color-all-0);
    --color-primary-container: var(--color-primary-30);
    --color-on-primary-container: var(--color-primary-30);
    --color-secondary: var(--color-secondary-70);
    --color-on-secondary: var(--color-all-0);
    --color-secondary-container: var(--color-secondary-90);
    --color-on-secondary-container: var(--color-secondary-30);
    --color-tertiary: var(--color-tertiary-70);
    --color-on-tertiary: var(--color-all-0);
    --color-tertiary-container: var(--color-tertiary-90);
    --color-on-tertiary-container: var(--color-tertiary-30);
    --color-error: var(--color-error-70);
    --color-on-error: var(--color-all-0);
    --color-error-container: var(--color-error-90);
    --color-on-error-container: var(--color-error-30);
    --color-alert-container: var(--color-alert-90);
    --color-background: var(--color-neutral-99);
    --color-on-background: var(--color-neutral-10);
    --color-surface: var(--color-neutral-99);
    --color-surface-1: #25272b;
    --color-surface-2: #2a2c32;
    --color-surface-3: #2f3039;
    --color-surface-4: #34353f;
    --color-surface-5: #383844;
    --color-on-surface: var(--color-neutral-10);
    --color-surface-variant: var(--color-neutral-variant-90);
    --color-on-surface-variant: var(--color-neutral-variant-40);
    --color-outline: var(--color-neutral-variant-60);
    --color-primary-hover: var(--color-primary-60);
    --color-primary-pressed: var(--color-primary-80);
    --color-danger-hover: var(--color-error-60);
    --color-danger-pressed: var(--color-error-80);
    --color-text: var(--color-neutral-10);
    --color-text-link: var(--color-primary-40);
    --color-text-secondary: var(--color-neutral-50);
    --color-placeholder: var(--color-neutral-70);
    --color-border: var(--color-neutral-80);
    --color-divider: var(--color-neutral-90);
    --color-disabled: var(--color-neutral-80);
    --color-base: var(--color-surface);
    --color-layer-1: var(--color-surface-2);
    --color-layer-2: var(--color-surface-4);
    --color-float: var(--color-surface-4);
    --color-layer-light: var(--color-surface-4);
    --color-inverse-surface: var(--color-neutral-20);
    --color-inverse-on-surface: var(--color-neutral-95);
    --color-inverse-primary: var(--color-primary-80);
    --color-shadow: var(--color-all-100);
    --color-white: #fff;
    --color-button-icon: var(--color-primary-20);
    --color-icon-background: #3a3b59;
    --color-hover: #f7f8f814;
    --color-pressed: #f7f8f81f;
    --color-focused: #f7f8f829;
    --color-hover-variant: #cabeff14;
    --color-pressed-variant: #cabeff1f;
    --color-focused-variant: #cabeff29;
    --shadow-1: 0 4px 8px #00000014;
    --shadow-2: 0 4px 12px #0000001f;
    --shadow-2-reversed: 0 -4px 12px #0000001f;
    --shadow-3: 0 4px 16px #0003;
    --color-checkbox-disabled-background: #f7f8f814;
    --color-checkbox-checked-disabled-background: var(--color-primary-40);
    --color-danger-toast-background: var(--color-error-99);
    --color-danger-focused: #ffb4a929;
    --color-tooltip-background: var(--color-surface-4);
    --color-tooltip-text: var(--color-neutral-10);
    --color-overlay: #0000004d;
    --color-drawer-overlay: #0009;
    --color-guide-dropdown-background: var(--color-neutral-variant-80);
    --color-guide-dropdown-border: var(--color-neutral-variant-70);
    --color-skeleton-shimmer-rgb: 42, 44, 50;
  }
}

@keyframes nplLDa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nplLDa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

div.nplLDa_toast {
  font: var(--font-body-2);
  color: var(--color-text);
  box-shadow: var(--shadow-1);
  max-width: 412px;
  border-radius: 8px;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

div.nplLDa_toast .nplLDa_image {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.nplLDa_toast .nplLDa_message {
  flex: 1;
  margin: 0 12px;
}

div.nplLDa_toast.nplLDa_success {
  border: 1px solid var(--color-success-70);
  background-color: var(--color-success-99);
}

div.nplLDa_toast.nplLDa_error {
  border: 1px solid var(--color-error);
  background-color: var(--color-danger-toast-background);
  white-space: pre-line;
}

@keyframes uLSymG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes uLSymG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.uLSymG_overlay {
  background: var(--color-overlay);
  z-index: 101;
  padding: 12px 0;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.uLSymG_content {
  min-height: 100%;
  flex-direction: column;
  flex: 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uLSymG_content:focus-visible {
  outline: none;
}

.uLSymG_fullScreen {
  z-index: 100;
  position: fixed;
  inset: 0;
}

.uLSymG_fullScreen:focus-visible {
  outline: none;
}

@keyframes xsNU1W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes xsNU1W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.xsNU1W_link {
  max-width: -moz-fit-content;
  max-width: fit-content;
  font: var(--font-body-2);
  color: var(--color-text-link);
  cursor: pointer;
  border-color: #0000;
  gap: 4px;
  text-decoration: none;
  display: inline-flex;
}

.xsNU1W_link.xsNU1W_trailingIcon {
  flex-direction: row-reverse;
}

.xsNU1W_link:active {
  color: var(--color-primary-pressed);
}

.xsNU1W_link:disabled {
  color: var(--color-disabled);
  cursor: not-allowed;
}

.xsNU1W_link:not(:disabled):hover {
  text-underline-offset: 2px;
  text-decoration: underline;
}

.xsNU1W_link > svg {
  vertical-align: baseline;
  display: inline-block;
}

@keyframes GjtMga_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes GjtMga_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.GjtMga_container {
  overflow: hidden;
}

.GjtMga_container .GjtMga_title {
  color: var(--color-text);
}

.GjtMga_container .GjtMga_titleEllipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.GjtMga_container .GjtMga_subtitle {
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.GjtMga_container .GjtMga_learnMore:not(:first-child) {
  margin-left: 4px;
}

.GjtMga_container.GjtMga_large .GjtMga_title {
  font: var(--font-title-1);
}

.GjtMga_container.GjtMga_large .GjtMga_subtitle {
  font: var(--font-body-2);
}

.GjtMga_container.GjtMga_medium .GjtMga_title {
  font: var(--font-title-2);
}

.GjtMga_container.GjtMga_medium .GjtMga_subtitle {
  font: var(--font-body-2);
}

.GjtMga_container.GjtMga_small .GjtMga_title {
  font: var(--font-title-2);
}

.GjtMga_container.GjtMga_small .GjtMga_subtitle {
  font: var(--font-body-3);
  margin-top: 0;
}

@keyframes _0JmSgq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _0JmSgq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._0JmSgq_button {
  border: none;
  border-color: none;
  color: var(--color-primary);
  font: var(--font-label-2);
  background: none;
  border-radius: 6px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 4px;
  transition: background .2s ease-in-out;
  display: flex;
}

._0JmSgq_button ._0JmSgq_icon > svg {
  color: var(--color-text-secondary);
  display: block;
}

._0JmSgq_button:disabled ._0JmSgq_icon > svg {
  color: var(--color-neutral-80);
}

._0JmSgq_button:active {
  background: var(--color-pressed);
}

._0JmSgq_button:not(:disabled) {
  cursor: pointer;
}

._0JmSgq_button:not(:disabled):hover {
  background: var(--color-hover);
}

._0JmSgq_button._0JmSgq_small {
  height: 24px;
  width: 24px;
}

._0JmSgq_button._0JmSgq_small ._0JmSgq_icon > svg {
  height: 16px;
  width: 16px;
}

._0JmSgq_button._0JmSgq_medium {
  height: 28px;
  width: 28px;
}

._0JmSgq_button._0JmSgq_medium ._0JmSgq_icon > svg {
  height: 20px;
  width: 20px;
}

._0JmSgq_button._0JmSgq_large {
  height: 32px;
  width: 32px;
}

._0JmSgq_button._0JmSgq_large ._0JmSgq_icon > svg {
  height: 24px;
  width: 24px;
}

@keyframes XBPPva_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes XBPPva_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.XBPPva_container {
  width: calc(100% - 48px);
  min-width: 352px;
  box-shadow: var(--shadow-3);
  flex-direction: column;
  margin: 0 24px;
  padding: 24px;
  display: flex;
}

.XBPPva_container .XBPPva_header {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  display: flex;
}

.XBPPva_container .XBPPva_header .XBPPva_closeIcon {
  color: var(--color-text-secondary);
}

.XBPPva_container .XBPPva_footer {
  flex-shrink: 0;
  justify-content: flex-end;
  padding-top: 24px;
  display: flex;
}

.XBPPva_container .XBPPva_footer > :not(:first-child) {
  margin-left: 16px;
}

.XBPPva_container.XBPPva_medium {
  max-width: 600px;
}

.XBPPva_container.XBPPva_large {
  max-width: 784px;
}

.XBPPva_container.XBPPva_xlarge {
  max-width: 1224px;
}

@keyframes gKmepq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes gKmepq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.gKmepq_content {
  font: var(--font-body-2);
}

.gKmepq_content > :not(:first-child) {
  margin: 24px 0 0;
}

@keyframes _8LEt0W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8LEt0W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8LEt0W_container {
  height: 100%;
  padding: 24px;
}

._8LEt0W_content {
  height: 100%;
  color: var(--color-text);
  text-align: center;
  overflow: hidden;
}

._8LEt0W_content svg {
  margin: 15% 0 16px;
}

._8LEt0W_content ._8LEt0W_message {
  font: var(--font-body-2);
}

@keyframes oDlJ3a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes oDlJ3a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.oDlJ3a_app {
  position: absolute;
  inset: 0;
}

@keyframes LvYW9G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes LvYW9G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.LvYW9G_topbar {
  width: 100%;
  flex: 0 0 64px;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.LvYW9G_topbar .LvYW9G_logo {
  width: auto;
  height: 28px;
  color: var(--color-text);
}

.VbL0Mq_app {
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

.VbL0Mq_content {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.oYXfRW_spacer {
  flex: 1;
}

@keyframes lhWvLa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes lhWvLa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.lhWvLa_tipBubble {
  background: var(--color-tooltip-background);
  color: var(--color-tooltip-text);
  box-shadow: var(--shadow-2);
  font: var(--font-body-2);
  max-width: 300px;
  border-radius: 8px;
  padding: 8px 12px;
  position: absolute;
}

.lhWvLa_tipBubble.lhWvLa_successful {
  background: var(--color-success-60);
}

.lhWvLa_tipBubble.lhWvLa_invisible {
  opacity: 0;
}

.lhWvLa_tipBubble a, .lhWvLa_tipBubble a:active {
  color: #cabeff;
}

.lhWvLa_tipBubble .lhWvLa_arrow {
  width: 10px;
  height: 10px;
  background-color: inherit;
  border-radius: 2px 0;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%)rotate(45deg);
}

.lhWvLa_tipBubble.lhWvLa_top .lhWvLa_arrow {
  top: 100%;
}

.lhWvLa_tipBubble.lhWvLa_right .lhWvLa_arrow {
  top: 50%;
  left: 0%;
}

.lhWvLa_tipBubble.lhWvLa_bottom .lhWvLa_arrow {
  top: 0%;
}

.lhWvLa_tipBubble.lhWvLa_left .lhWvLa_arrow {
  top: 50%;
  left: 100%;
}

@keyframes nJBtma_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nJBtma_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nJBtma_anchor {
  display: inline-block;
}

@keyframes _1_r_5W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _1_r_5W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._1_r_5W_overlay {
  background: none;
  position: fixed;
  inset: 0;
}

._1_r_5W_overlay ._1_r_5W_content {
  position: relative;
}

._1_r_5W_overlay ._1_r_5W_content:focus {
  outline: none;
}

@keyframes xXo6qa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes xXo6qa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.xXo6qa_field:not(:first-child) {
  margin-top: 24px;
}

.xXo6qa_headline {
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.xXo6qa_headline .xXo6qa_title {
  font: var(--font-label-2);
  color: var(--color-text);
}

.xXo6qa_headline .xXo6qa_title .xXo6qa_multiple {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-left: 4px;
}

.xXo6qa_headline .xXo6qa_toggleTipButton {
  margin-left: 2px;
}

.xXo6qa_headline .xXo6qa_required {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

@keyframes clFIpG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes clFIpG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.clFIpG_suffix {
  width: 32px;
  height: 32px;
  display: none;
}

.clFIpG_hideTextContainerContent input {
  -webkit-text-security: disc;
}

.clFIpG_container {
  border: 1px solid var(--color-border);
  height: 36px;
  background: var(--color-layer-1);
  font: var(--font-body-2);
  border-radius: 6px;
  outline: 3px solid #0000;
  align-items: center;
  padding: 0 12px;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
}

.clFIpG_container.clFIpG_withIcon {
  align-items: center;
  display: flex;
}

.clFIpG_container.clFIpG_withIcon .clFIpG_icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.clFIpG_container:focus-within {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.clFIpG_container:focus-within .clFIpG_suffix {
  display: block;
}

.clFIpG_container input {
  -webkit-appearance: none;
  appearance: none;
  color: var(--color-text);
  font: var(--font-body-2);
  background: none;
  flex: 1;
  padding: 0;
}

.clFIpG_container input::placeholder {
  color: var(--color-placeholder);
}

.clFIpG_container input:-webkit-autofill {
  box-shadow: 0 0 0 30px var(--color-layer-1) inset;
  -webkit-text-fill-color: var(--color-text);
  caret-color: var(--color-text);
}

.clFIpG_container input[type="date"] {
  height: 20px;
}

.clFIpG_container input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: none;
  background-color: var(--color-text-secondary);
  width: 16px;
  height: 18px;
  -webkit-mask-image: url("calendar.5bc8703a.png");
  mask-image: url("calendar.5bc8703a.png");
  -webkit-mask-size: 20px 20px;
  mask-size: 20px 20px;
}

.clFIpG_container input[type="number"] {
  -moz-appearance: textfield;
}

.clFIpG_container input[type="number"]::-webkit-outer-spin-button, .clFIpG_container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.clFIpG_container.clFIpG_disabled {
  background: var(--color-inverse-on-surface);
  color: var(--color-text-secondary);
  border-color: var(--color-border);
}

.clFIpG_container.clFIpG_readOnly {
  background: var(--color-layer-2);
  color: var(--color-text);
  border-color: var(--color-border);
}

.clFIpG_container.clFIpG_readOnly:focus-within {
  border-color: var(--color-border);
  outline-color: #0000;
}

.clFIpG_container.clFIpG_error {
  border-color: var(--color-error);
}

.clFIpG_container.clFIpG_error:focus-within {
  outline-color: var(--color-danger-focused);
}

.clFIpG_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes _3zvtua_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3zvtua_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3zvtua_page {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

._3zvtua_contentContainer {
  flex: 1;
  padding-bottom: 24px;
  overflow-y: auto;
}

._3zvtua_content {
  max-width: 858px;
  background-color: var(--color-layer-1);
  border-radius: 16px;
  flex-direction: column;
  margin: 0 auto 16px;
  padding: 48px;
  display: flex;
}

@keyframes CqHBjq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes CqHBjq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.CqHBjq_progressBar {
  gap: 4px;
  display: flex;
}

.CqHBjq_progressBar .CqHBjq_stepIndicator {
  height: 4px;
  background-color: var(--color-neutral-variant-80);
  flex: 1;
}

.CqHBjq_progressBar .CqHBjq_stepIndicator.CqHBjq_active {
  background-color: var(--color-text-link);
}

@keyframes YcFmOG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes YcFmOG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.YcFmOG_container {
  height: 80px;
}

.YcFmOG_container .YcFmOG_actions {
  height: 100%;
  background-color: var(--color-layer-1);
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 68px;
  display: flex;
}

@keyframes FKu9hG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FKu9hG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FKu9hG_radio {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  font: var(--font-body-2);
}

.FKu9hG_radio:not(:last-child) {
  margin-bottom: 8px;
}

.FKu9hG_radio .FKu9hG_content {
  align-items: center;
  display: flex;
}

.FKu9hG_radio .FKu9hG_content .FKu9hG_indicator {
  border: 2px solid var(--color-neutral-60);
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.FKu9hG_radio .FKu9hG_content .FKu9hG_indicator:before {
  content: "";
  background: var(--color-layer-1);
  width: 10px;
  height: 10px;
  border: 2px solid var(--color-layer-1);
  border-radius: 50%;
  display: block;
}

.FKu9hG_radio .FKu9hG_content .FKu9hG_icon {
  color: var(--color-text-secondary);
  margin-right: 8px;
}

.FKu9hG_radio .FKu9hG_content .FKu9hG_icon > svg {
  display: block;
}

.FKu9hG_card {
  outline: 1px solid var(--color-neutral-90);
  border: 1px solid #0000;
  border-radius: 16px;
  padding: 12px;
}

.FKu9hG_card:not(:last-child) {
  margin-bottom: unset;
}

.FKu9hG_card .FKu9hG_content {
  display: block;
  position: relative;
}

.FKu9hG_card .FKu9hG_content .FKu9hG_indicator {
  border-radius: unset;
  border: unset;
  margin-right: unset;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.FKu9hG_card .FKu9hG_content .FKu9hG_indicator svg {
  opacity: 0;
}

.FKu9hG_card .FKu9hG_content .FKu9hG_indicator:before {
  display: none;
}

.FKu9hG_card .FKu9hG_content .FKu9hG_icon {
  vertical-align: middle;
  color: var(--color-text-secondary);
  margin-right: 8px;
}

.FKu9hG_card .FKu9hG_content .FKu9hG_icon > svg {
  display: unset;
}

.FKu9hG_card .FKu9hG_content .FKu9hG_disabledLabel {
  background: var(--color-neutral-90);
  font: var(--font-label-3);
  color: var(--color-text);
  border-radius: 10px;
  padding: 2px 8px;
}

.FKu9hG_compact {
  border: 1px solid var(--color-border);
  font: var(--font-label-2);
  flex: 1;
  position: relative;
}

.FKu9hG_compact:first-child {
  border-radius: 12px 0 0 12px;
}

.FKu9hG_compact:last-child {
  border-radius: 0 12px 12px 0;
}

.FKu9hG_compact:not(:first-child) {
  border-left: none;
}

.FKu9hG_compact:not(:last-child) {
  margin-bottom: unset;
}

.FKu9hG_compact .FKu9hG_content {
  height: 100%;
  padding: 20px;
}

.FKu9hG_compact .FKu9hG_content .FKu9hG_icon {
  margin-right: 16px;
}

.FKu9hG_radio.FKu9hG_checked .FKu9hG_content .FKu9hG_indicator {
  border-color: var(--color-primary);
}

.FKu9hG_radio.FKu9hG_checked .FKu9hG_content .FKu9hG_indicator:before {
  background: var(--color-primary);
}

.FKu9hG_card.FKu9hG_checked {
  border-color: var(--color-primary);
  outline: 1px solid var(--color-primary);
}

.FKu9hG_card.FKu9hG_checked .FKu9hG_content .FKu9hG_indicator svg {
  opacity: 1;
}

.FKu9hG_compact.FKu9hG_checked {
  color: var(--color-primary);
  border-color: var(--color-primary);
  background-color: var(--color-hover-variant);
}

.FKu9hG_compact.FKu9hG_checked .FKu9hG_content .FKu9hG_icon {
  color: var(--color-primary);
}

.FKu9hG_compact.FKu9hG_checked:not(:first-child):before {
  content: "";
  width: 1px;
  background-color: var(--color-primary);
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
}

.FKu9hG_radio.FKu9hG_disabled {
  cursor: not-allowed;
  color: var(--color-disabled);
}

.FKu9hG_radio.FKu9hG_disabled .FKu9hG_content .FKu9hG_indicator {
  border-color: var(--color-disabled);
}

.FKu9hG_radio.FKu9hG_disabled .FKu9hG_content .FKu9hG_indicator:before {
  background: var(--color-layer-1);
}

.FKu9hG_card.FKu9hG_disabled {
  background-color: var(--color-layer-2);
  border-color: var(--color-layer-2);
  outline: unset;
}

.FKu9hG_compact.FKu9hG_disabled {
  cursor: not-allowed;
  background-color: var(--color-layer-2);
}

.FKu9hG_compact.FKu9hG_disabled .FKu9hG_content .FKu9hG_icon {
  color: var(--color-text-secondary);
}

.FKu9hG_card:not(.FKu9hG_disabled):focus {
  outline: 1px solid var(--color-primary);
  box-shadow: var(--shadow-2);
}

.FKu9hG_card:not(.FKu9hG_disabled):hover {
  box-shadow: var(--shadow-2);
}

.FKu9hG_compact:not(.FKu9hG_disabled):hover {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.FKu9hG_compact:not(.FKu9hG_disabled):hover .FKu9hG_content .FKu9hG_icon {
  color: var(--color-primary);
}

.FKu9hG_compact:not(.FKu9hG_disabled):hover:not(:first-child):before {
  content: "";
  width: 1px;
  background-color: var(--color-primary);
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
}

@keyframes BWvkSa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BWvkSa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BWvkSa_radioGroup input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
}

.BWvkSa_compact {
  flex-wrap: nowrap;
  align-items: stretch;
  display: flex;
}

@keyframes RkZH6W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes RkZH6W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.RkZH6W_item {
  border: 1px solid var(--color-border);
  min-height: 80px;
  font: var(--font-label-2);
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--color-layer-1);
  color: var(--color-text);
  border-radius: 12px;
  align-items: center;
  padding: 20px;
  display: flex;
}

.RkZH6W_item .RkZH6W_icon {
  color: var(--color-text-secondary);
  vertical-align: middle;
  margin-right: 16px;
}

.RkZH6W_item .RkZH6W_icon > svg {
  display: block;
}

.RkZH6W_item .RkZH6W_content .RkZH6W_tag {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
}

.RkZH6W_item .RkZH6W_content .RkZH6W_trailingTag {
  margin-left: 4px;
}

.RkZH6W_item.RkZH6W_disabled {
  border-color: var(--color-layer-2);
  background-color: var(--color-layer-2);
}

.RkZH6W_item.RkZH6W_disabled:hover {
  cursor: not-allowed;
}

.RkZH6W_item:not(.RkZH6W_disabled).RkZH6W_selected {
  border-color: var(--color-primary);
  background-color: var(--color-hover-variant);
  color: var(--color-primary);
}

.RkZH6W_item:not(.RkZH6W_disabled).RkZH6W_selected .RkZH6W_icon {
  color: var(--color-primary);
}

.RkZH6W_item:not(.RkZH6W_disabled):hover {
  cursor: pointer;
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.RkZH6W_item:not(.RkZH6W_disabled):hover .RkZH6W_icon {
  color: var(--color-primary);
}

@keyframes -XdXDW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -XdXDW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-XdXDW_selector {
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  display: grid;
}

@keyframes nHqO1W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nHqO1W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nHqO1W_title {
  font: var(--font-title-1);
  margin-top: 24px;
}

.nHqO1W_description {
  font: var(--font-body-2);
  margin-top: 12px;
}

.nHqO1W_form {
  width: 100%;
  margin-top: 24px;
}

.nHqO1W_form .nHqO1W_titleSelector {
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
}

.nHqO1W_form .nHqO1W_option {
  min-height: 60px;
  justify-content: center;
  display: flex;
}

.nHqO1W_form .nHqO1W_cardFieldHeadline {
  margin-bottom: 8px;
}

._4UZYCa_divider {
  height: 0;
  border-bottom: 1px solid var(--color-divider);
}

@keyframes yqkDhW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes yqkDhW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.yqkDhW_reachLogto {
  width: 100%;
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.yqkDhW_reachLogto .yqkDhW_reachLogtoInfo {
  align-items: center;
  display: flex;
}

.yqkDhW_reachLogto .yqkDhW_reachLogtoInfo .yqkDhW_reachLogtoIcon {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  margin-right: 16px;
}

.yqkDhW_reachLogto .yqkDhW_reachLogtoInfo .yqkDhW_reachLogtoTitle {
  font: var(--font-title-3);
}

.yqkDhW_reachLogto .yqkDhW_reachLogtoInfo .yqkDhW_reachLogtoDescription {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  padding-right: 16px;
}

@keyframes _3me8SW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3me8SW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3me8SW_content {
  max-width: 858px;
  align-items: center;
  padding: 48px 128px;
}

._3me8SW_congratsImage {
  width: 160px;
  height: 160px;
}

._3me8SW_title {
  font: var(--font-title-1);
  text-align: center;
  margin-top: 24px;
}

._3me8SW_description {
  font: var(--font-body-2);
  text-align: center;
  margin: 4px 0 24px;
}

._3me8SW_strong {
  font-weight: 500;
}

._3me8SW_buttonIcon {
  opacity: .7;
}

._3me8SW_divider {
  width: 100%;
  margin: 32px 0;
}

._3me8SW_emailUs {
  background-color: var(--color-layer-light);
  border: unset;
}

@keyframes _90kR-a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _90kR-a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._90kR-a_content {
  box-shadow: var(--shadow-2);
  border-radius: 8px;
  position: absolute;
}

._90kR-a_content._90kR-a_onTop {
  box-shadow: var(--shadow-2-reversed);
}

._90kR-a_content:focus {
  outline: none;
}

._90kR-a_dropdownContainer {
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  border-radius: 8px;
}

._90kR-a_title {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
}

._90kR-a_overlay {
  background: none;
  position: fixed;
  inset: 0;
}

@keyframes YIacqW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes YIacqW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.YIacqW_item {
  font: var(--font-body-2);
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  padding: 8px;
  display: flex;
  overflow: hidden;
}

.YIacqW_item:hover {
  background: var(--color-hover);
}

.YIacqW_item.YIacqW_danger {
  color: var(--color-error);
}

.YIacqW_item .YIacqW_icon {
  align-items: center;
  margin-right: 16px;
  display: flex;
}

@keyframes Lxs6kq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Lxs6kq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Lxs6kq_container {
  border: 1px solid var(--color-border);
  font: var(--font-body-2);
  border-radius: 6px;
  outline: 3px solid #0000;
  align-items: center;
  padding: 6px 12px;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
}

.Lxs6kq_container:focus, .Lxs6kq_container.Lxs6kq_highlight {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.Lxs6kq_container .Lxs6kq_brick {
  width: 24px;
  height: 24px;
  border: 1px solid var(--color-divider);
  border-radius: 4px;
  flex-shrink: 0;
  margin-right: 8px;
  display: inline-block;
}

@keyframes c0y7XW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes c0y7XW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.c0y7XW_uploader {
  border: 1px dashed var(--color-border);
  border-radius: 8px;
  padding: 13px;
}

.c0y7XW_uploader > input {
  display: none;
}

.c0y7XW_uploader .c0y7XW_placeholder {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.c0y7XW_uploader .c0y7XW_placeholder .c0y7XW_icon {
  color: var(--color-text-secondary);
}

.c0y7XW_uploader .c0y7XW_placeholder .c0y7XW_uploadingIcon {
  width: 20px;
  height: 20px;
  color: var(--color-primary);
}

.c0y7XW_uploader .c0y7XW_placeholder .c0y7XW_actionDescription {
  font: var(--font-body-2);
  -webkit-user-select: none;
  user-select: none;
  margin-top: 4px;
}

.c0y7XW_uploader:hover {
  cursor: pointer;
  border-color: var(--color-primary);
}

.c0y7XW_uploader:hover .c0y7XW_placeholder .c0y7XW_icon {
  color: var(--color-primary);
}

.c0y7XW_uploader.c0y7XW_dragActive {
  cursor: copy;
  background-color: var(--color-hover-variant);
  border-color: var(--color-primary);
}

.c0y7XW_uploader.c0y7XW_dragActive .c0y7XW_placeholder .c0y7XW_icon {
  color: var(--color-primary);
}

.c0y7XW_uploader.c0y7XW_uploaderError {
  border-color: var(--color-error);
}

@keyframes nYnzqa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nYnzqa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nYnzqa_imageUploader {
  border: 1px dashed var(--color-border);
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  padding: 15px 8px;
  display: flex;
  position: relative;
}

.nYnzqa_imageUploader .nYnzqa_delete {
  display: none;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.nYnzqa_imageUploader > img {
  height: 40px;
  max-width: 100%;
  object-fit: contain;
  cursor: not-allowed;
}

.nYnzqa_imageUploader:hover .nYnzqa_delete {
  display: block;
}

@keyframes r6Zt1q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes r6Zt1q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.r6Zt1q_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

.r6Zt1q_error {
  color: var(--color-error);
}

@keyframes V-qgmW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes V-qgmW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.V-qgmW_inspire {
  background-color: var(--color-base);
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding: 16px 20px;
  display: flex;
}

.V-qgmW_inspire .V-qgmW_inspireContent {
  flex-direction: column;
  margin-right: 24px;
  display: flex;
}

.V-qgmW_inspire .V-qgmW_inspireContent .V-qgmW_inspireTitle {
  font: var(--font-title-2);
  margin-bottom: 4px;
}

.V-qgmW_inspire .V-qgmW_inspireContent .V-qgmW_inspireDescription {
  font: var(--font-body-2);
}

.V-qgmW_inspire .V-qgmW_button {
  border-color: var(--color-neutral-variant-80);
  padding-right: 28px;
}

.V-qgmW_inspire .V-qgmW_button:not(:disabled):not(:active):hover, .V-qgmW_inspire .V-qgmW_button:not(:disabled):active:hover {
  background: var(--color-layer-1) center / 90% no-repeat url("fireworks.016a5a54.png");
}

.V-qgmW_inspire .V-qgmW_button:not(:disabled):active:hover {
  background-color: #0000;
}

.od0qPW_defaultIcon {
  color: var(--color-text-secondary);
}

.od0qPW_disabledDefaultIcon {
  color: var(--color-neutral-70);
}

@keyframes _25xENG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _25xENG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._25xENG_preview {
  background: var(--color-surface-variant);
}

._25xENG_preview iframe {
  border: none;
}

._25xENG_preview._25xENG_web {
  position: relative;
}

._25xENG_preview._25xENG_web ._25xENG_deviceWrapper {
  width: 498px;
  height: 626.057px;
  margin: 0 auto;
  overflow: hidden;
}

._25xENG_preview._25xENG_web ._25xENG_deviceWrapper ._25xENG_device iframe {
  width: 700px;
  height: 880px;
  margin-top: -126.971px;
  margin-left: -101px;
  transform: scaleX(.711429)scaleY(.711429);
}

._25xENG_preview._25xENG_mobile {
  position: relative;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper {
  padding: 40px 0;
  overflow: hidden;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device {
  width: 260px;
  height: 606.667px;
  border-radius: 26px;
  margin: 0 auto;
  overflow: hidden;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device ._25xENG_topBar {
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device ._25xENG_topBar ._25xENG_time {
  font: var(--font-label-2);
  transform-origin: 0;
  flex: 1;
  transform: scale(.8);
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device._25xENG_dark {
  background: #1a1c1d;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device._25xENG_dark ._25xENG_topBar {
  color: #fff;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device._25xENG_light {
  background: #fff;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device._25xENG_light ._25xENG_topBar {
  color: #000;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device iframe {
  width: 390px;
  height: 844px;
  margin-top: -140.667px;
  margin-left: -65px;
  transform: scaleX(.666667)scaleY(.666667);
}

@keyframes DiRmtq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes DiRmtq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.DiRmtq_container .DiRmtq_button {
  padding: 8px;
}

.DiRmtq_container .DiRmtq_icon {
  color: var(--color-text-secondary);
}

.DiRmtq_container.DiRmtq_large .DiRmtq_icon {
  width: 24px;
  height: 24px;
}

.DiRmtq_container.DiRmtq_medium .DiRmtq_icon {
  width: 20px;
  height: 20px;
}

.DiRmtq_container.DiRmtq_small .DiRmtq_icon {
  width: 16px;
  height: 16px;
}

@keyframes ml23sq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ml23sq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ml23sq_tab {
  font: var(--font-label-2);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 6px;
  align-items: center;
  padding: 4px 8px;
  display: flex;
}

.ml23sq_tab .ml23sq_icon {
  color: var(--color-primary);
  margin-right: 8px;
}

.ml23sq_tab .ml23sq_icon > svg {
  display: block;
}

.ml23sq_tab.ml23sq_selected {
  color: var(--color-layer-1);
  background-color: var(--color-inverse-primary);
}

.ml23sq_tab.ml23sq_selected .ml23sq_icon {
  color: var(--color-static-white);
  opacity: .7;
}

.ml23sq_tab:not(.ml23sq_selected):hover {
  background-color: var(--color-hover-variant);
}

@keyframes D_MJEG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes D_MJEG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.D_MJEG_container {
  background-color: var(--color-layer-1);
  border: 1px solid var(--color-surface-5);
  border-radius: 8px;
  align-items: center;
  gap: 12px;
  padding: 4px;
  display: flex;
}

@keyframes _7syY9W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7syY9W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7syY9W_container {
  background-color: var(--color-neutral-variant-90);
  border-radius: 16px;
  flex-direction: column;
  padding: 24px;
  display: flex;
}

._7syY9W_topBar {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@keyframes N3FFUG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes N3FFUG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.N3FFUG_content {
  min-width: min-content;
  justify-content: center;
  padding: 0 68px;
  display: flex;
}

.N3FFUG_content > div {
  max-width: 800px;
  min-width: 540px;
  flex: 1;
}

.N3FFUG_content .N3FFUG_config {
  background-color: var(--color-layer-1);
  border-radius: 8px;
  margin-right: 24px;
  padding: 48px;
}

.N3FFUG_content .N3FFUG_config .N3FFUG_title {
  font: var(--font-title-1);
  margin-top: 24px;
}

.N3FFUG_content .N3FFUG_config .N3FFUG_cardFieldHeadline {
  margin-bottom: 8px;
}

.N3FFUG_content .N3FFUG_config .N3FFUG_authnSelector {
  grid-template-columns: repeat(2, 1fr);
}

.N3FFUG_content .N3FFUG_preview {
  align-self: flex-start;
  position: sticky;
  top: 0;
}

.N3FFUG_continueActions {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

@keyframes yGyQLW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes yGyQLW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.yGyQLW_fieldWrapper {
  padding: 8px;
}

.yGyQLW_fieldWrapper > :not(:first-child) {
  margin-top: 24px;
}

.yGyQLW_fieldWrapper .yGyQLW_title {
  background-color: var(--color-layer-2);
  width: 80px;
  height: 16px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.yGyQLW_fieldWrapper .yGyQLW_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite yGyQLW_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.yGyQLW_fieldWrapper .yGyQLW_field {
  background-color: var(--color-layer-2);
  width: 100%;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.yGyQLW_fieldWrapper .yGyQLW_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite yGyQLW_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.yGyQLW_fieldWrapper:not(:first-child) {
  margin-top: 12px;
}

.yGyQLW_preview {
  background: var(--color-surface-variant);
  border-radius: 12px;
  padding: 24px;
  overflow: hidden;
}

.yGyQLW_preview .yGyQLW_header {
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 8px;
  display: flex;
}

.yGyQLW_preview .yGyQLW_header .yGyQLW_actions {
  gap: 12px;
  display: flex;
}

.yGyQLW_preview .yGyQLW_header .yGyQLW_smallButton {
  width: 30px;
  height: 30px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-left: 10px;
  position: relative;
  overflow: hidden;
}

.yGyQLW_preview .yGyQLW_header .yGyQLW_smallButton:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite yGyQLW_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.yGyQLW_preview .yGyQLW_header .yGyQLW_button {
  width: 104px;
  height: 30px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-left: 10px;
  position: relative;
  overflow: hidden;
}

.yGyQLW_preview .yGyQLW_header .yGyQLW_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite yGyQLW_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.yGyQLW_preview .yGyQLW_mobile {
  width: 375px;
  height: 667px;
  background: var(--color-surface);
  transform-origin: top;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  transform: scale(.6);
}

.yGyQLW_preview .yGyQLW_mobile .yGyQLW_logo {
  width: 64px;
  height: 64px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 64px;
  position: relative;
  overflow: hidden;
}

.yGyQLW_preview .yGyQLW_mobile .yGyQLW_logo:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite yGyQLW_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.yGyQLW_preview .yGyQLW_mobile .yGyQLW_slogan {
  width: 177px;
  height: 16px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin: 12px 0 40px;
  position: relative;
  overflow: hidden;
}

.yGyQLW_preview .yGyQLW_mobile .yGyQLW_slogan:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite yGyQLW_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.yGyQLW_preview .yGyQLW_mobile .yGyQLW_field {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.yGyQLW_preview .yGyQLW_mobile .yGyQLW_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite yGyQLW_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.yGyQLW_preview .yGyQLW_mobile .yGyQLW_button {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 40px;
  position: relative;
  overflow: hidden;
}

.yGyQLW_preview .yGyQLW_mobile .yGyQLW_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite yGyQLW_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.yGyQLW_preview .yGyQLW_mobile .yGyQLW_social {
  width: 180px;
  height: 24px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.yGyQLW_preview .yGyQLW_mobile .yGyQLW_social:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite yGyQLW_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.Am3ekq_container {
  width: 40px;
  height: 40px;
  background-color: var(--color-hover);
  border-radius: 8px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Am3ekq_container > img {
  width: 28px;
  height: 28px;
}

.Am3ekq_container.Am3ekq_large {
  width: 60px;
  height: 60px;
  border-radius: 12px;
}

.Am3ekq_container.Am3ekq_large > img {
  width: 42px;
  height: 42px;
}

.Am3ekq_container.Am3ekq_small {
  width: 20px;
  height: 20px;
  border-radius: unset;
  background-color: #0000;
}

.Am3ekq_container.Am3ekq_small > img {
  width: 20px;
  height: 20px;
}

.Am3ekq_logo {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.Am3ekq_logo.Am3ekq_large {
  width: 60px;
  height: 60px;
}

.Am3ekq_logo.Am3ekq_small {
  width: 20px;
  height: 20px;
}

@keyframes k8ahaW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes k8ahaW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.k8ahaW_content {
  align-items: center;
}

.k8ahaW_congrats {
  width: 160px;
  height: 160px;
}

.k8ahaW_title {
  font: var(--font-title-1);
  text-align: center;
  margin-top: 24px;
}

.k8ahaW_description {
  text-align: center;
  font: var(--font-body-2);
  margin-top: 12px;
}

.k8ahaW_form {
  width: 100%;
  margin-top: 24px;
}

.k8ahaW_form .k8ahaW_cardFieldHeadline {
  margin-bottom: 8px;
}

@keyframes Cc-bDq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Cc-bDq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Cc-bDq_broadcast {
  background-color: var(--color-neutral-variant-90);
  color: var(--color-neutral-variant-30);
  font: var(--font-label-2);
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  border-radius: 16px;
  padding: 6px 16px;
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.Cc-bDq_broadcast .Cc-bDq_link {
  margin-left: 4px;
}

@keyframes SoD9uq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes SoD9uq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SoD9uq_row {
  color: var(--color-neutral-variant-30);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  width: calc(100% - 40px);
  background: none;
  border: none;
  border-radius: 8px;
  align-items: center;
  margin: 4px 24px 4px 16px;
  padding: 8px;
  text-decoration: none;
  display: flex;
}

.SoD9uq_row .SoD9uq_icon {
  height: 20px;
  flex: 0 0 20px;
}

.SoD9uq_row .SoD9uq_icon svg {
  color: var(--color-neutral-variant-50);
  width: 20px;
  height: 20px;
}

.SoD9uq_row:not(.SoD9uq_active):hover {
  background: var(--color-hover-variant);
}

.SoD9uq_row.SoD9uq_active {
  background: var(--color-surface-5);
  color: var(--color-text-link);
}

.SoD9uq_row.SoD9uq_active .SoD9uq_icon svg {
  color: var(--color-primary-50);
}

.SoD9uq_row > div + div {
  margin-left: 16px;
}

.SoD9uq_row .SoD9uq_title {
  font: var(--font-label-2);
}

@keyframes s9DuQG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes s9DuQG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.s9DuQG_title {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  font: var(--font-section-head-2);
  color: var(--color-neutral-variant-70);
  padding: 8px 24px;
}

@keyframes iUzj5a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes iUzj5a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.iUzj5a_main .iUzj5a_row {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.iUzj5a_main .iUzj5a_row .iUzj5a_icon {
  margin-right: 24px;
}

.iUzj5a_main .iUzj5a_row:not(:last-child) {
  margin-bottom: 24px;
}

.iUzj5a_main .iUzj5a_row .iUzj5a_text {
  flex: 1;
}

.iUzj5a_main .iUzj5a_row .iUzj5a_text .iUzj5a_title {
  font: var(--font-title-3);
  color: var(--color-text);
}

.iUzj5a_main .iUzj5a_row .iUzj5a_text .iUzj5a_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.iUzj5a_main .iUzj5a_row .iUzj5a_button {
  width: 90px;
}

@keyframes _3ZtC7q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3ZtC7q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3ZtC7q_sidebar {
  width: 248px;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 24px;
  display: flex;
  overflow-y: auto;
}

._3ZtC7q_sidebar > div + div {
  margin-top: 24px;
}

._3ZtC7q_sidebar ._3ZtC7q_spacer {
  flex: 1 1 0;
  margin: 0;
}

@keyframes hrko9G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes hrko9G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.hrko9G_title {
  white-space: normal;
}

.hrko9G_content {
  margin-top: -20px;
}

.hrko9G_content .hrko9G_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.hrko9G_content > :not(:first-child) {
  margin-top: 24px;
}

@keyframes wjiRLq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wjiRLq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wjiRLq_container {
  width: 28px;
  height: 28px;
  color: var(--color-text-link);
  background: var(--color-focused-variant);
  font: var(--font-title-2);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wjiRLq_container.wjiRLq_active {
  color: var(--color-on-primary);
  background: var(--color-primary);
}

.wjiRLq_container.wjiRLq_completed {
  background: var(--color-primary);
}

.wjiRLq_container.wjiRLq_completed > svg {
  color: var(--color-on-primary);
}

@keyframes _0i2oZG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _0i2oZG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._0i2oZG_progress {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  padding: 8px;
  transition: background-color .2s ease-in-out;
  display: flex;
}

._0i2oZG_progress:hover {
  background-color: var(--color-hover-variant);
}

._0i2oZG_progress._0i2oZG_active {
  background-color: var(--color-focused-variant);
}

._0i2oZG_progress ._0i2oZG_icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

._0i2oZG_progress span {
  font: var(--font-label-2);
  color: var(--color-text);
}

._0i2oZG_dropdown {
  padding: 0;
}

._0i2oZG_dropdownTitle {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  padding: 12px 20px 12px 16px;
}

._0i2oZG_dropdownItemWrapper ._0i2oZG_index {
  width: 20px;
  height: 20px;
  font: var(--font-section-head-1);
}

._0i2oZG_dropdownItemWrapper ._0i2oZG_index > svg {
  width: 16px;
  height: 16px;
}

._0i2oZG_dropdownItemWrapper ._0i2oZG_dropdownItem {
  height: 42px;
  border-top: 1px solid var(--color-hover);
  border-radius: unset;
  border-bottom: 1px solid #0000;
  padding: 0 20px 0 16px;
}

._0i2oZG_dropdownItemWrapper ._0i2oZG_dropdownItem:last-child {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

._0i2oZG_dropdownItemWrapper ._0i2oZG_dropdownItem:hover:not(:last-child) {
  border-bottom-color: var(--color-hover);
}

._0i2oZG_dropdownItemWrapper ._0i2oZG_dropdownItem:hover:not(:last-child) + ._0i2oZG_dropdownItem {
  border-top-color: #0000;
}

.cX_Dda_wrapper {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.cX_Dda_wrapper.cX_Dda_micro {
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.cX_Dda_wrapper.cX_Dda_small {
  width: 24px;
  height: 24px;
}

.cX_Dda_wrapper.cX_Dda_medium {
  width: 32px;
  height: 32px;
}

.cX_Dda_wrapper.cX_Dda_large {
  width: 40px;
  height: 40px;
}

.cX_Dda_wrapper.cX_Dda_xlarge {
  width: 60px;
  height: 60px;
}

.cX_Dda_avatar {
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  font: var(--font-headline-3);
  width: 48px;
  height: 48px;
  object-fit: cover;
  transform-origin: 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cX_Dda_avatar.cX_Dda_micro {
  transform: scale(.416);
}

.cX_Dda_avatar.cX_Dda_small {
  transform: scale(.5);
}

.cX_Dda_avatar.cX_Dda_medium {
  transform: scale(.667);
}

.cX_Dda_avatar.cX_Dda_large {
  transform: scale(.833);
}

.cX_Dda_avatar.cX_Dda_xlarge {
  transform: scale(1.25);
}

@keyframes _26bd8a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _26bd8a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._26bd8a_userInfo {
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  align-items: center;
  display: flex;
}

._26bd8a_nameWrapper {
  flex-direction: column;
  margin-left: 12px;
  display: flex;
}

._26bd8a_nameWrapper ._26bd8a_name {
  font: var(--font-label-2);
  color: var(--color-text);
}

._26bd8a_nameWrapper ._26bd8a_email {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
}

@keyframes _a0lyG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _a0lyG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._a0lyG_container {
  font: var(--font-body-2);
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  padding: 10px 16px;
  display: flex;
  position: relative;
}

._a0lyG_container:hover {
  background: var(--color-hover);
}

._a0lyG_icon {
  color: var(--color-text-secondary);
  align-items: center;
  display: flex;
}

._a0lyG_title {
  font: var(--font-body-2);
  margin-left: 16px;
}

._a0lyG_menu {
  visibility: hidden;
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  box-shadow: var(--shadow-2);
  border-radius: 8px;
  position: absolute;
  top: -4px;
  right: calc(100% + 5px);
}

._a0lyG_menu._a0lyG_visible {
  visibility: visible;
}

._a0lyG_menuOption {
  min-width: 200px;
  margin: 4px;
  padding: 10px 22px 10px 32px;
  position: relative;
}

._a0lyG_menuOption._a0lyG_selected {
  color: var(--color-text-link);
}

._a0lyG_menuOption ._a0lyG_tick {
  position: absolute;
  top: 10px;
  left: 8px;
}

@keyframes K9xCrq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes K9xCrq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.K9xCrq_container {
  border-radius: 8px;
  align-items: center;
  margin-left: 16px;
  padding: 8px;
  display: flex;
}

.K9xCrq_container .K9xCrq_image {
  background-color: var(--color-layer-2);
  width: 36px;
  height: 36px;
  border-radius: 6px;
  margin-right: 8px;
  position: relative;
  overflow: hidden;
}

.K9xCrq_container .K9xCrq_image:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite K9xCrq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.K9xCrq_container .K9xCrq_name {
  background-color: var(--color-layer-2);
  width: 85px;
  height: 20px;
  color: var(--color-text);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.K9xCrq_container .K9xCrq_name:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite K9xCrq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes -QMq-W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -QMq-W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-QMq-W_container {
  cursor: pointer;
  margin-left: 16px;
  position: relative;
}

.-QMq-W_container:after {
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 8px;
  transition: background .2s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.-QMq-W_container:hover:after {
  background: var(--color-hover);
}

.-QMq-W_container.-QMq-W_active:after {
  background: var(--color-focused);
}

.-QMq-W_dropdown {
  min-width: 320px;
}

.-QMq-W_dropdown .-QMq-W_userInfo {
  padding: 16px 20px;
}

.-QMq-W_dropdownItem {
  min-width: 170px;
  margin: 4px;
  padding: 10px 16px;
}

.-QMq-W_dropdownItem.-QMq-W_loading {
  opacity: .6;
  cursor: default;
}

.-QMq-W_dropdownItem.-QMq-W_loading:hover {
  background-color: unset;
}

.-QMq-W_dropdownItem .-QMq-W_icon {
  color: var(--color-text-secondary);
}

.-QMq-W_dropdownItem .-QMq-W_spinner {
  margin-left: 24px;
}

@keyframes _3rTxHG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3rTxHG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3rTxHG_topbar {
  width: 100%;
  flex: 0 0 64px;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

._3rTxHG_topbar ._3rTxHG_logo {
  width: auto;
  height: 28px;
  color: var(--color-text);
}

._3rTxHG_topbar ._3rTxHG_line {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px;
}

._3rTxHG_topbar ._3rTxHG_text {
  font: var(--font-title-2);
  color: var(--color-text);
}

@keyframes wZF7yG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wZF7yG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wZF7yG_app {
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

.wZF7yG_topbarShadow {
  box-shadow: var(--shadow-2);
}

@keyframes nASDTG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nASDTG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nASDTG_content {
  min-width: 200px;
  padding: 4px;
}

.nASDTG_dropdownTitle {
  padding: 12px;
}

@keyframes pDxB8q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pDxB8q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.pDxB8q_container {
  color: var(--color-text);
  font: var(--font-body-2);
  cursor: default;
  border-radius: 6px;
  font-family: Roboto Mono, monospace;
  display: inline-block;
}

.pDxB8q_container.pDxB8q_contained {
  background: var(--color-layer-2);
  padding: 4px 8px;
}

.pDxB8q_container.pDxB8q_border {
  background: var(--color-layer-2);
  border: 1px solid var(--color-border);
  padding: 4px 12px;
}

.pDxB8q_container .pDxB8q_row {
  cursor: text;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.pDxB8q_container .pDxB8q_row .pDxB8q_content {
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.pDxB8q_container .pDxB8q_row .pDxB8q_copyToolTipAnchor {
  margin-left: 8px;
}

.pDxB8q_container.pDxB8q_default .pDxB8q_row .pDxB8q_copyToolTipAnchor {
  margin-left: 12px;
}

.pDxB8q_container.pDxB8q_small .pDxB8q_row .pDxB8q_copyToolTipAnchor {
  margin-left: 4px;
}

.pDxB8q_container.pDxB8q_small .pDxB8q_row .pDxB8q_iconButton {
  height: 20px;
  width: 20px;
}

.pDxB8q_container.pDxB8q_small .pDxB8q_row .pDxB8q_iconButton .pDxB8q_icon svg {
  width: 12px;
  height: 12px;
}

@keyframes -TV4Uq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -TV4Uq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-TV4Uq_container {
  padding: 24px 32px;
  display: flex;
}

.-TV4Uq_introduction {
  width: 296px;
  flex-shrink: 0;
  margin-right: 56px;
  padding-bottom: 24px;
}

.-TV4Uq_introduction > :not(:first-child) {
  margin-top: 8px;
}

.-TV4Uq_introduction .-TV4Uq_title {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--color-neutral-variant-60);
}

.-TV4Uq_introduction .-TV4Uq_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

@media screen and (max-width: 1080px) {
  .-TV4Uq_container {
    flex-direction: column;
  }

  .-TV4Uq_container .-TV4Uq_introduction {
    width: 100%;
    margin-right: unset;
  }
}

.-TV4Uq_form {
  flex-grow: 1;
  padding: 0 4px;
  overflow: hidden;
}

@keyframes ohuMpq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ohuMpq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ohuMpq_container {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
}

.ohuMpq_container > :not(:first-child) {
  margin-top: 16px;
}

.ohuMpq_container .ohuMpq_header {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  align-items: center;
  padding: 24px;
  display: flex;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_icon {
  background-color: var(--color-layer-2);
  width: 60px;
  height: 60px;
  border-radius: 12px;
  margin-right: 24px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_icon:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_header .ohuMpq_wrapper {
  flex-direction: column;
  display: flex;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_wrapper .ohuMpq_title {
  background-color: var(--color-layer-2);
  width: 113px;
  height: 28px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_wrapper .ohuMpq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_header .ohuMpq_wrapper .ohuMpq_tags {
  background-color: var(--color-layer-2);
  width: 453px;
  height: 20px;
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_wrapper .ohuMpq_tags:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_header .ohuMpq_button {
  background-color: var(--color-layer-2);
  width: 158px;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_tabBar {
  width: 100%;
  height: 28px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_tabBar:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_content .ohuMpq_introduction .ohuMpq_title {
  background-color: var(--color-layer-2);
  height: 16px;
  width: 80px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_content .ohuMpq_introduction .ohuMpq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_content .ohuMpq_introduction .ohuMpq_description .ohuMpq_text {
  background-color: var(--color-layer-2);
  width: 100%;
  height: 10px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_content .ohuMpq_introduction .ohuMpq_description .ohuMpq_text:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_content .ohuMpq_introduction .ohuMpq_description .ohuMpq_text + .ohuMpq_text {
  margin-top: 8px;
}

.ohuMpq_container .ohuMpq_content .ohuMpq_form .ohuMpq_field {
  background-color: var(--color-layer-2);
  width: 100%;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_content .ohuMpq_form .ohuMpq_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_content .ohuMpq_form .ohuMpq_field + .ohuMpq_field {
  margin-top: 24px;
}

@keyframes _60TtZq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _60TtZq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._60TtZq_error {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

._60TtZq_error > :not(:first-child) {
  margin-top: 8px;
}

._60TtZq_error ._60TtZq_image {
  width: 200px;
  height: 200px;
}

._60TtZq_error ._60TtZq_title {
  text-align: center;
  font: var(--font-label-2);
}

._60TtZq_error ._60TtZq_content {
  font: var(--font-body-2);
  color: var(--color-neutral-50);
}

@keyframes _1JPFKW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _1JPFKW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._1JPFKW_container {
  min-height: 100%;
  min-width: min-content;
  flex-direction: column;
  display: flex;
}

._1JPFKW_container > :not(:first-child) {
  margin-top: 16px;
}

._1JPFKW_backLink {
  -webkit-user-select: none;
  user-select: none;
  margin: 4px 0 0 4px;
}

@keyframes Ww65cW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Ww65cW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Ww65cW_nav {
  border-bottom: 1px solid var(--color-surface-5);
  margin-top: 4px;
  display: flex;
}

@keyframes t5qcVa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes t5qcVa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.t5qcVa_item {
  align-items: center;
  display: flex;
}

.t5qcVa_item:not(:last-child) {
  margin-right: 24px;
}

.t5qcVa_item .t5qcVa_link {
  font: var(--font-label-2);
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 2px 6px;
}

.t5qcVa_item .t5qcVa_link a {
  color: var(--color-neutral-30);
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

.t5qcVa_item .t5qcVa_link:hover {
  background-color: var(--color-hover-variant);
}

.t5qcVa_item .t5qcVa_selected {
  color: var(--color-text-link);
  position: relative;
}

.t5qcVa_item .t5qcVa_selected a {
  color: var(--color-text-link);
}

.t5qcVa_item .t5qcVa_selected:after {
  content: "";
  border-top: 2px solid var(--color-text-link);
  border-radius: 8px 8px 0 0;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
}

.t5qcVa_item .t5qcVa_errors {
  font: var(--font-label-3);
  color: var(--color-white);
  background-color: var(--color-error-50);
  vertical-align: middle;
  cursor: default;
  border-radius: 10px;
  margin-bottom: 4px;
  margin-left: 2px;
  padding: 2px 6px;
}

@keyframes nfnr9a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nfnr9a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nfnr9a_permissionPage {
  height: 100%;
}

.nfnr9a_deleteConfirm > :not(:first-child) {
  margin-top: 24px;
}

.nfnr9a_deleteConfirm .nfnr9a_description {
  font: var(--font-body-2);
}

.nfnr9a_deleteConfirm .nfnr9a_highlight {
  color: var(--color-primary-50);
}

.nfnr9a_header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nfnr9a_header .nfnr9a_info {
  display: flex;
}

.nfnr9a_header .nfnr9a_info .nfnr9a_icon {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-left: 8px;
}

.nfnr9a_header .nfnr9a_info .nfnr9a_meta {
  flex-direction: column;
  justify-content: space-between;
  margin-left: 24px;
  display: flex;
}

.nfnr9a_header .nfnr9a_info .nfnr9a_meta .nfnr9a_name {
  font: var(--font-title-1);
  color: var(--color-text);
}

.nfnr9a_header .nfnr9a_operations {
  align-items: center;
  display: flex;
}

.nfnr9a_header .nfnr9a_operations .nfnr9a_moreIcon {
  color: var(--color-text-secondary);
}

.nfnr9a_header .nfnr9a_operations > :not(:first-child) {
  margin-left: 12px;
}

@keyframes _9nt4fW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _9nt4fW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._9nt4fW_search {
  align-items: center;
  display: flex;
}

._9nt4fW_search > :not(:first-child) {
  margin-left: 8px;
}

._9nt4fW_search ._9nt4fW_searchIcon {
  color: var(--color-text-secondary);
}

@keyframes Rm7RFG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Rm7RFG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Rm7RFG_container {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.Rm7RFG_container .Rm7RFG_positionInfo {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.Rm7RFG_container .Rm7RFG_pagination {
  height: 28px;
  justify-content: right;
  margin: 0;
  padding-inline-start: 16px;
  display: flex;
}

.Rm7RFG_container .Rm7RFG_pagination li {
  list-style: none;
}

.Rm7RFG_container .Rm7RFG_pagination li:not(:first-child) {
  margin-left: 8px;
}

.Rm7RFG_container .Rm7RFG_pagination li .Rm7RFG_button {
  min-width: 28px;
  height: 28px;
  text-overflow: unset;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  padding: 0 6px;
  display: block;
}

.Rm7RFG_container .Rm7RFG_pagination li .Rm7RFG_button > span {
  margin: 0 auto;
}

.Rm7RFG_container .Rm7RFG_pagination li .Rm7RFG_button.Rm7RFG_active {
  border-color: var(--color-text-link);
  color: var(--color-text-link);
}

.Rm7RFG_container li.Rm7RFG_disabled {
  cursor: not-allowed;
}

.Rm7RFG_container li.Rm7RFG_disabled .Rm7RFG_button {
  background: var(--color-neutral-95);
}

.Rm7RFG_container.Rm7RFG_pico .Rm7RFG_pagination {
  height: 20px;
}

.Rm7RFG_container.Rm7RFG_pico .Rm7RFG_pagination li .Rm7RFG_button {
  height: 20px;
  min-width: unset;
  border: unset;
  background: unset;
  border-radius: 4px;
  padding: 0;
}

@keyframes mrki9q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes mrki9q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.mrki9q_tableEmptyWrapper {
  border-bottom: unset;
}

.mrki9q_tableEmptyWrapper .mrki9q_content {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  display: flex;
}

@keyframes NKlFWq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes NKlFWq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.NKlFWq_tableError {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.NKlFWq_tableError > :not(:first-child) {
  margin-top: 8px;
}

.NKlFWq_tableError .NKlFWq_title {
  text-align: center;
  font: var(--font-label-2);
}

.NKlFWq_tableError .NKlFWq_content {
  font: var(--font-body-2);
  color: var(--color-neutral-50);
}

@keyframes BgyFAq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BgyFAq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BgyFAq_loading .BgyFAq_itemPreview {
  align-items: center;
  display: flex;
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_avatar {
  background-color: var(--color-layer-2);
  width: 40px;
  height: 40px;
  border-radius: 12px;
  flex-shrink: 0;
  margin-right: 16px;
  position: relative;
  overflow: hidden;
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_avatar:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BgyFAq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_content {
  width: 100%;
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_content .BgyFAq_title {
  background-color: var(--color-layer-2);
  height: 12px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_content .BgyFAq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BgyFAq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_content .BgyFAq_subTitle {
  background-color: var(--color-layer-2);
  height: 8px;
  border-radius: 8px;
  margin-top: 8px;
  position: relative;
  overflow: hidden;
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_content .BgyFAq_subTitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BgyFAq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.BgyFAq_loading .BgyFAq_rect {
  background-color: var(--color-layer-2);
  height: 32px;
  max-width: 344px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.BgyFAq_loading .BgyFAq_rect:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BgyFAq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes MJr8fq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes MJr8fq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.MJr8fq_container {
  min-width: min-content;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.MJr8fq_tableContainer {
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.MJr8fq_tableContainer .MJr8fq_filterContainer {
  background-color: var(--color-layer-1);
  border-radius: 12px 12px 0 0;
  padding: 12px 12px 0;
}

.MJr8fq_tableContainer .MJr8fq_filterContainer .MJr8fq_filter {
  border-bottom: 1px solid var(--color-divider);
  padding-bottom: 12px;
}

.MJr8fq_tableContainer table {
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  border: none;
}

.MJr8fq_tableContainer .MJr8fq_headerTable {
  background-color: var(--color-layer-1);
  border-radius: 12px 12px 0 0;
  padding: 0 12px;
}

.MJr8fq_tableContainer .MJr8fq_headerTable thead tr th {
  font: var(--font-label-2);
  color: var(--color-text);
  border-bottom: unset;
  text-align: left;
  padding: 12px;
}

.MJr8fq_tableContainer .MJr8fq_headerTable.MJr8fq_hideTopBorderRadius {
  border-radius: 0;
}

.MJr8fq_tableContainer .MJr8fq_bodyTable {
  background-color: var(--color-layer-1);
  border-radius: 0 0 12px 12px;
  padding: 0 12px 12px;
  overflow-y: auto;
}

.MJr8fq_tableContainer .MJr8fq_bodyTable tbody tr td {
  font: var(--font-body-2);
  border-top: 1px solid var(--color-divider);
  border-bottom: unset;
  padding: 12px;
}

.MJr8fq_tableContainer .MJr8fq_bodyTable tbody tr.MJr8fq_clickable {
  cursor: pointer;
}

.MJr8fq_tableContainer .MJr8fq_bodyTable.MJr8fq_empty, .MJr8fq_tableContainer .MJr8fq_bodyTable.MJr8fq_empty table, .MJr8fq_tableContainer .MJr8fq_bodyTable.MJr8fq_empty table tbody {
  height: 100%;
}

.MJr8fq_tableContainer tr.MJr8fq_hoverEffect:hover {
  background: var(--color-hover);
}

.MJr8fq_tableContainer tr.MJr8fq_hoverEffect:hover td, .MJr8fq_tableContainer tr.MJr8fq_hoverEffect:hover + tr td {
  border-top: 1px solid #0000;
}

.MJr8fq_tableContainer tr.MJr8fq_hoverEffect:hover td:first-child {
  border-radius: 8px 0 0 8px;
}

.MJr8fq_tableContainer tr.MJr8fq_hoverEffect:hover td:last-child {
  border-radius: 0 8px 8px 0;
}

.MJr8fq_tableContainer.MJr8fq_hasBorder .MJr8fq_filterContainer {
  border: 1px solid var(--color-divider);
  border-bottom: unset;
}

.MJr8fq_tableContainer.MJr8fq_hasBorder .MJr8fq_filterContainer .MJr8fq_filter {
  border-bottom: unset;
}

.MJr8fq_tableContainer.MJr8fq_hasBorder .MJr8fq_headerTable {
  border: 1px solid var(--color-divider);
  padding: 0;
}

.MJr8fq_tableContainer.MJr8fq_hasBorder .MJr8fq_bodyTable {
  border: 1px solid var(--color-divider);
  border-top: unset;
  padding: 0;
}

.MJr8fq_tableContainer.MJr8fq_hasBorder .MJr8fq_bodyTable tr:first-child td {
  border-top: 1px solid #0000;
}

.MJr8fq_tableContainer.MJr8fq_hasBorder tr.MJr8fq_hoverEffect:hover td:first-child, .MJr8fq_tableContainer.MJr8fq_hasBorder tr.MJr8fq_hoverEffect:hover td:last-child {
  border-radius: 0;
}

.MJr8fq_pagination {
  margin-top: 16px;
}

@keyframes ljXalG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ljXalG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ljXalG_empty {
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  display: flex;
}

.ljXalG_empty .ljXalG_title {
  font: var(--font-label-2);
  margin-bottom: 8px;
}

.ljXalG_empty .ljXalG_description {
  font: var(--font-body-2);
  color: var(--color-neutral-50);
  margin-bottom: 8px;
}

.ljXalG_empty.ljXalG_large .ljXalG_image {
  width: 256px;
  height: 256px;
  margin-bottom: 24px;
}

.ljXalG_empty.ljXalG_medium .ljXalG_image {
  width: 200px;
  height: 200px;
  margin-bottom: 24px;
}

.ljXalG_empty.ljXalG_small .ljXalG_image {
  width: 128px;
  height: 128px;
}

@keyframes _bbcwq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _bbcwq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._bbcwq_placeholder {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

._bbcwq_placeholder ._bbcwq_image > svg {
  width: 200px;
  height: 200px;
}

._bbcwq_placeholder ._bbcwq_title {
  font: var(--font-label-2);
}

._bbcwq_placeholder ._bbcwq_description {
  max-width: 310px;
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

._bbcwq_placeholder ._bbcwq_action {
  margin-top: 24px;
}

@keyframes vPj7DW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vPj7DW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vPj7DW_permissionTable {
  color: var(--color-text);
  flex: 1;
  margin-bottom: 24px;
}

.vPj7DW_permissionTable .vPj7DW_filter {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.vPj7DW_permissionTable .vPj7DW_filter .vPj7DW_searchInput {
  width: 306px;
}

.vPj7DW_permissionTable .vPj7DW_filter .vPj7DW_createButton {
  margin-left: 8px;
}

.vPj7DW_permissionTable .vPj7DW_name {
  max-width: 100%;
  vertical-align: bottom;
  background: var(--color-neutral-95);
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 6px;
  padding: 4px 8px;
  display: inline-block;
  overflow: hidden;
}

.vPj7DW_permissionTable .vPj7DW_description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vPj7DW_permissionTable .vPj7DW_link {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.vPj7DW_permissionTable .vPj7DW_deleteColumn {
  text-align: right;
}

@keyframes _7BqVIW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7BqVIW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7BqVIW_container {
  width: 100%;
  height: 0;
  transition: height .3s ease-out .1s;
  position: sticky;
  bottom: 0;
  overflow: hidden;
}

._7BqVIW_container ._7BqVIW_actionBar {
  height: 60px;
  border: 1px solid var(--color-line-divider);
  background-color: var(--color-float);
  box-shadow: var(--shadow-3);
  border-radius: 12px 12px 0 0;
  justify-content: flex-end;
  padding: 12px 32px;
  transition: transform .3s ease-out;
  display: flex;
  transform: translateY(100%);
}

._7BqVIW_container ._7BqVIW_actionBar > button + button {
  margin-left: 12px;
}

._7BqVIW_container._7BqVIW_active {
  height: 60px;
  overflow: visible;
}

._7BqVIW_container._7BqVIW_active ._7BqVIW_actionBar {
  transition: transform .3s ease-in;
  transform: translateY(0);
}

@keyframes l9fJqW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes l9fJqW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.l9fJqW_container {
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 8px;
  display: flex;
}

.l9fJqW_container.l9fJqW_withSubmitActionBar {
  padding-bottom: 0;
}

.l9fJqW_container > :not(:first-child) {
  margin-top: 16px;
}

.l9fJqW_container .l9fJqW_fields {
  flex-grow: 1;
}

.l9fJqW_container .l9fJqW_fields > :not(:first-child) {
  margin-top: 16px;
}

@keyframes mTaj3G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes mTaj3G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.mTaj3G_item {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.mTaj3G_item > div:not(:first-child) {
  margin-left: 12px;
}

.mTaj3G_item .mTaj3G_content {
  align-items: center;
  margin-top: -4px;
  padding-right: 16px;
  display: flex;
  overflow: hidden;
}

.mTaj3G_item .mTaj3G_content > div:not(:last-child) {
  margin-right: 8px;
}

.mTaj3G_item .mTaj3G_content .mTaj3G_meta {
  overflow: hidden;
}

.mTaj3G_item .mTaj3G_content .mTaj3G_meta .mTaj3G_title {
  font: var(--font-body-2);
  color: var(--color-text-link);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

.mTaj3G_item .mTaj3G_content .mTaj3G_meta .mTaj3G_subtitle {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mTaj3G_item.mTaj3G_compact .mTaj3G_content {
  margin-top: unset;
}

.mTaj3G_item.mTaj3G_compact .mTaj3G_content .mTaj3G_meta {
  align-items: baseline;
  display: flex;
}

.mTaj3G_item.mTaj3G_compact .mTaj3G_content .mTaj3G_meta .mTaj3G_title {
  margin-right: 4px;
}

@keyframes PxkItW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PxkItW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.PxkItW_container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-bottom: 24px;
  display: flex;
}

.PxkItW_headline {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.PxkItW_table {
  flex: 1;
  margin-top: 16px;
  overflow: hidden;
}

@keyframes _2WfC1G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _2WfC1G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._2WfC1G_icon {
  flex-shrink: 0;
}

._2WfC1G_pagination {
  margin-top: 16px;
}

@keyframes rotating_a964f6 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shimmer_a964f6 {
  100% {
    transform: translateX(100%);
  }
}

.content_a964f6 {
  width: 50%;
  max-width: 900px;
  min-width: 770px;
  background: var(--color-base);
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.content_a964f6 .wrapper_a964f6 {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.content_a964f6 .wrapper_a964f6 .header_a964f6 {
  background-color: var(--color-layer-1);
  height: 64px;
  flex-shrink: 0;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.content_a964f6 .wrapper_a964f6 .body_a964f6 {
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.overlay_a964f6 {
  background: var(--color-drawer-overlay);
  position: fixed;
  inset: 0;
}

.ReactModal__Content[role="drawer"] {
  transition: transform .3s ease-in-out;
  transform: translateX(100%);
}

.ReactModal__Content--after-open[role="drawer"] {
  transform: translateX(0);
}

.ReactModal__Content--before-close[role="drawer"] {
  transform: translateX(100%);
}

@keyframes VVOXbG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes VVOXbG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.VVOXbG_container {
  background: #34353f;
  border-radius: 16px;
  padding: 24px 24px 24px 16px;
  position: relative;
  overflow-y: auto;
}

.VVOXbG_container .VVOXbG_placeholder {
  color: #95969f;
  font: var(--font-body-2);
  position: absolute;
  inset: 24px 32px 24px 24px;
}

.VVOXbG_container .VVOXbG_copy {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  justify-content: right;
  align-items: center;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
}

.VVOXbG_container:hover .VVOXbG_copy {
  opacity: 1;
}

.VVOXbG_container .VVOXbG_editor {
  position: relative;
  overflow-x: auto;
}

.VVOXbG_container .VVOXbG_editor textarea {
  width: 100%;
  white-space: pre;
  word-break: normal;
  resize: none;
  color: #fefefe;
  -webkit-text-fill-color: transparent;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Roboto Mono, monospace;
  font-size: 14px;
  line-height: 1.5;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.VVOXbG_container .VVOXbG_editor textarea, .VVOXbG_container .VVOXbG_editor pre {
  min-height: 80px;
  display: flex;
}

.VVOXbG_container .VVOXbG_editor::-webkit-scrollbar {
  display: none;
}

.VVOXbG_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes PkjlTG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PkjlTG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.PkjlTG_container {
  background-color: var(--color-layer-light);
  border-radius: 8px;
  flex-direction: column;
  margin: 24px 0;
  display: flex;
}

.PkjlTG_container .PkjlTG_summary {
  color: var(--color-text-secondary);
  font: var(--font-label-2);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  padding: 16px;
  display: flex;
}

.PkjlTG_container .PkjlTG_summary .PkjlTG_arrow {
  margin-right: 8px;
  transition: transform .3s;
  transform: rotate(0);
}

.PkjlTG_container .PkjlTG_details {
  color: var(--color-text);
  font: var(--font-body-2);
  border-top: 1px solid var(--color-divider);
  padding: 16px;
}

.PkjlTG_container .PkjlTG_details > p {
  margin: 0;
}

.PkjlTG_container.PkjlTG_expanded .PkjlTG_summary .PkjlTG_arrow {
  transform: rotate(90deg);
}

@keyframes _7zJXOa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7zJXOa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7zJXOa_header {
  background-color: var(--color-layer-1);
  height: 64px;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

._7zJXOa_header ._7zJXOa_separator {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px 0 16px;
}

._7zJXOa_header ._7zJXOa_closeIcon {
  color: var(--color-text-secondary);
}

._7zJXOa_header ._7zJXOa_githubToolTipAnchor {
  margin-right: 16px;
}

._7zJXOa_header ._7zJXOa_githubIcon div {
  display: flex;
}

._7zJXOa_header ._7zJXOa_githubIcon svg {
  color: var(--color-text);
}

._7zJXOa_header ._7zJXOa_getSampleButton {
  margin: 0 60px 0 24px;
}

@keyframes svvxza_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes svvxza_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.svvxza_select {
  background: var(--color-layer-1);
  border: 1px solid var(--color-border);
  font: var(--font-body-2);
  cursor: pointer;
  border-radius: 8px;
  outline: 3px solid #0000;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 12px;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
}

.svvxza_select .svvxza_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.svvxza_select.svvxza_open {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.svvxza_select.svvxza_readOnly {
  background: var(--color-inverse-on-surface);
  color: var(--color-text);
  border-color: var(--color-border);
  cursor: default;
}

.svvxza_select.svvxza_error {
  border-color: var(--color-error);
}

.svvxza_select.svvxza_error.svvxza_open {
  outline-color: var(--color-danger-focused);
}

.svvxza_select .svvxza_icon {
  color: var(--color-text-secondary);
  margin-left: 4px;
  display: flex;
}

.svvxza_select .svvxza_clear {
  display: none;
}

.svvxza_select .svvxza_arrow svg {
  width: 20px;
  height: 20px;
}

.svvxza_select.svvxza_clearable:hover .svvxza_clear {
  display: block;
}

.svvxza_select.svvxza_clearable:hover .svvxza_arrow {
  display: none;
}

.svvxza_select.svvxza_small {
  height: 30px;
}

.svvxza_select.svvxza_medium {
  height: 32px;
}

.svvxza_select.svvxza_large {
  height: 36px;
}

.svvxza_dropdown {
  max-height: 288px;
  padding: 4px;
}

@keyframes PJGclW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PJGclW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.PJGclW_card {
  flex-direction: column;
  padding: 20px 24px;
  scroll-margin: 20px;
  display: block;
}

.PJGclW_card .PJGclW_congrats {
  width: 160px;
  height: 160px;
  margin: 4px auto 32px;
  display: block;
}

.PJGclW_card .PJGclW_congratsText {
  width: 100%;
}

.PJGclW_card .PJGclW_title {
  font: var(--font-title-1);
}

.PJGclW_card .PJGclW_subtitle {
  font: var(--font-body-2);
  color: var(--color-text);
  margin-top: 12px;
}

.PJGclW_card .PJGclW_radioGroup {
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 24px;
  margin-right: 0;
  display: flex;
}

.PJGclW_card .PJGclW_radio {
  width: 240px;
  max-width: unset;
  font: var(--font-label-2);
  border-radius: 8px;
  padding: 20px;
}

.PJGclW_card .PJGclW_select {
  background: var(--color-guide-dropdown-background);
  border-color: var(--color-guide-dropdown-border);
}

.PJGclW_card.PJGclW_folded {
  height: 56px;
  background: var(--color-neutral-variant-90);
  font: var(--font-body-2);
  color: var(--color-text);
  border-radius: 8px;
  flex-direction: row;
  flex: 0 0 56px;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.PJGclW_card.PJGclW_folded .PJGclW_tada {
  margin: 0 16px 0 0;
}

.PJGclW_card .PJGclW_buttonWrapper {
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
  display: flex;
}

@keyframes Q9BMNq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Q9BMNq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Q9BMNq_step {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  align-items: center;
  padding: 20px 24px;
  display: flex;
}

.Q9BMNq_step .Q9BMNq_index {
  background-color: var(--color-layer-2);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 16px;
  position: relative;
  overflow: hidden;
}

.Q9BMNq_step .Q9BMNq_index:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite Q9BMNq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.Q9BMNq_step .Q9BMNq_wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.Q9BMNq_step .Q9BMNq_wrapper .Q9BMNq_title {
  background-color: var(--color-layer-2);
  width: 140px;
  height: 24px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.Q9BMNq_step .Q9BMNq_wrapper .Q9BMNq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite Q9BMNq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.Q9BMNq_step .Q9BMNq_wrapper .Q9BMNq_subtitle {
  background-color: var(--color-layer-2);
  width: 400px;
  height: 20px;
  border-radius: 8px;
  margin-top: 4px;
  position: relative;
  overflow: hidden;
}

.Q9BMNq_step .Q9BMNq_wrapper .Q9BMNq_subtitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite Q9BMNq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.Q9BMNq_step + .Q9BMNq_step {
  margin-top: 24px;
}

@keyframes pLdshq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pLdshq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.pLdshq_container {
  background-color: var(--color-base);
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.pLdshq_container .pLdshq_content {
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 24px 24px 80px;
  display: flex;
  overflow-y: auto;
}

.pLdshq_container .pLdshq_content > * {
  max-width: 858px;
  width: 100%;
}

.pLdshq_container .pLdshq_content .pLdshq_banner {
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.pLdshq_markdownContent {
  margin-top: 24px;
}

@keyframes ZDF5ia_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ZDF5ia_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ZDF5ia_switch {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.ZDF5ia_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ZDF5ia_switch .ZDF5ia_slider {
  cursor: pointer;
  background-color: var(--color-neutral-90);
  border-radius: 12px;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.ZDF5ia_switch .ZDF5ia_slider:before {
  content: "";
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
  box-shadow: 0 3px 7px #0000001f;
}

.ZDF5ia_switch input:checked + .ZDF5ia_slider {
  background-color: var(--color-success-70);
}

.ZDF5ia_switch input:checked + .ZDF5ia_slider:before {
  transform: translateX(16px);
}

.ZDF5ia_wrapper {
  border: 1px solid var(--color-neutral-90);
  border-radius: 8px;
  align-items: center;
  padding: 16px;
  display: flex;
}

.ZDF5ia_wrapper .ZDF5ia_label {
  font: var(--font-body-2);
  flex: 1;
  margin-right: 8px;
}

@keyframes ck2QpG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ck2QpG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ck2QpG_deleteConfirm > :not(:first-child) {
  margin-top: 24px;
}

.ck2QpG_deleteConfirm .ck2QpG_description {
  font: var(--font-body-2);
}

.ck2QpG_deleteConfirm .ck2QpG_highlight {
  color: var(--color-primary-50);
}

.ck2QpG_textField {
  width: 100%;
}

.ck2QpG_header {
  flex: 0;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  display: flex;
}

.ck2QpG_header > :not(:first-child) {
  margin-left: 24px;
}

.ck2QpG_header .ck2QpG_icon {
  width: 60px;
  height: 60px;
  margin-left: 8px;
}

.ck2QpG_header .ck2QpG_operations {
  align-items: center;
  display: flex;
}

.ck2QpG_header .ck2QpG_operations .ck2QpG_moreIcon {
  color: var(--color-text-secondary);
}

.ck2QpG_header .ck2QpG_operations > :not(:first-child) {
  margin-left: 12px;
}

.ck2QpG_header .ck2QpG_metadata {
  flex: 1;
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_name {
  font: var(--font-title-1);
  color: var(--color-text);
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details {
  white-space: nowrap;
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details > * {
  display: inline-block;
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details > :not(:first-child) {
  margin-left: 8px;
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details .ck2QpG_type {
  background-color: var(--color-surface-variant);
  color: var(--color-text);
  font: var(--font-label-3);
  border-radius: 10px;
  padding: 2px 8px;
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details .ck2QpG_text {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details .ck2QpG_verticalBar {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 12px;
}

@keyframes q8OBxa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes q8OBxa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.q8OBxa_container {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.q8OBxa_container .q8OBxa_title {
  font: var(--font-label-2);
  color: var(--color-text);
  margin-top: 10px;
}

.q8OBxa_container .q8OBxa_subtitle, .q8OBxa_container .q8OBxa_description {
  font: var(--font-body-2);
  flex: 2;
  margin-top: 12px;
}

.q8OBxa_container .q8OBxa_subtitle {
  color: var(--color-text);
}

.q8OBxa_container .q8OBxa_description {
  color: var(--color-text-secondary);
}

.q8OBxa_container.q8OBxa_small .q8OBxa_subtitle, .q8OBxa_container.q8OBxa_small .q8OBxa_description {
  font: var(--font-body-3);
  flex: 2;
  margin-top: 4px;
}

@keyframes uufsOW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes uufsOW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.uufsOW_placeholder {
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  display: flex;
}

.uufsOW_placeholder .uufsOW_title {
  font: var(--font-title-1);
}

.uufsOW_placeholder .uufsOW_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  text-align: center;
  max-width: 600px;
  margin-top: 4px;
}

.uufsOW_placeholder .uufsOW_options {
  max-width: 736px;
  justify-content: space-between;
  align-items: stretch;
  gap: 16px;
  margin-top: 24px;
  display: flex;
}

.uufsOW_placeholder .uufsOW_options .uufsOW_option {
  border: 1px solid var(--color-divider);
  border-radius: 12px;
  flex-direction: column;
  flex: 1;
  padding: 12px;
  display: flex;
}

.uufsOW_placeholder .uufsOW_options .uufsOW_option .uufsOW_createButton {
  margin-top: 10px;
}

@keyframes lMkVDq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes lMkVDq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.lMkVDq_radioGroup {
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-top: 8px;
  display: grid;
}

@media screen and (max-width: 668px) {
  .lMkVDq_radioGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .lMkVDq_radioGroup {
    grid-template-columns: repeat(1, 1fr);
  }
}

.lMkVDq_error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 8px;
}

@keyframes _7_IiMW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7_IiMW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7_IiMW_icon {
  flex-shrink: 0;
}

._7_IiMW_pagination {
  margin-top: 16px;
}

._7_IiMW_applicationName {
  width: 360px;
}

.QCDwsq_link {
  color: var(--color-text-link);
  text-decoration: none;
}

@keyframes _3-KeZq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3-KeZq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3-KeZq_userName {
  font: var(--body-medium);
  color: var(--color-text);
}

._3-KeZq_userName ._3-KeZq_title {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 4px;
  display: inline-block;
  overflow: hidden;
}

._3-KeZq_userName ._3-KeZq_id {
  font: var(--body-small);
  color: var(--color-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

._3-KeZq_userName ._3-KeZq_link {
  color: var(--color-text-link);
  text-decoration: none;
  display: inline-block;
}

._1f0U4G_success {
  color: var(--color-success-60);
}

._1f0U4G_fail {
  color: var(--color-error-40);
}

._1f0U4G_icon {
  width: 60px;
  height: 60px;
}

._1f0U4G_label {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  text-align: center;
}

@keyframes tI75Cq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tI75Cq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tI75Cq_header {
  padding: 24px;
  display: flex;
}

.tI75Cq_header > :not(:first-child) {
  margin-left: 24px;
}

.tI75Cq_header .tI75Cq_content {
  flex: 1;
}

.tI75Cq_header .tI75Cq_content .tI75Cq_eventName {
  color: var(--color-text);
  font: var(--font-title-1);
}

.tI75Cq_header .tI75Cq_content .tI75Cq_basicInfo {
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
  display: flex;
}

.tI75Cq_header .tI75Cq_content .tI75Cq_basicInfo > * {
  width: 270px;
  margin: 0 16px 16px 0;
}

.tI75Cq_header .tI75Cq_content .tI75Cq_infoItem {
  word-break: break-word;
  color: var(--color-text);
  font: var(--font-body-2);
}

.tI75Cq_header .tI75Cq_content .tI75Cq_infoItem .tI75Cq_label {
  color: var(--color-text-secondary);
  font: var(--font-label-2);
  margin-bottom: 8px;
}

.tI75Cq_body {
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;
  padding-bottom: 0;
  display: flex;
}

.tI75Cq_body > :not(:first-child) {
  margin-top: 16px;
}

.tI75Cq_body .tI75Cq_main {
  padding-bottom: 16px;
}

@keyframes ji4gBq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ji4gBq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ji4gBq_eventName {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.ji4gBq_eventName .ji4gBq_icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 4px;
}

.ji4gBq_eventName .ji4gBq_icon.ji4gBq_success {
  color: var(--color-success-60);
}

.ji4gBq_eventName .ji4gBq_icon.ji4gBq_fail {
  color: var(--color-error-40);
}

.ji4gBq_eventName .ji4gBq_title {
  font: var(--font-body-2);
  color: var(--color-text-link);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
}

@keyframes _l4QRW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _l4QRW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._l4QRW_filter {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

._l4QRW_filter ._l4QRW_title {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
}

._l4QRW_filter ._l4QRW_eventSelector {
  width: 300px;
  margin-left: 8px;
}

._l4QRW_filter ._l4QRW_applicationSelector {
  width: 250px;
  margin-left: 8px;
}

@keyframes dvBGRa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes dvBGRa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.dvBGRa_markdown li {
  font: var(--font-body-2);
}

.dvBGRa_markdown li ul, .dvBGRa_markdown li ol {
  padding-inline-start: 1ch;
}

.dvBGRa_markdown ul {
  padding-inline-start: 4ch;
}

.dvBGRa_markdown ul > li {
  margin-block: 8px;
  padding-inline-start: 4px;
}

.dvBGRa_markdown ol {
  padding-inline-start: 2ch;
}

.dvBGRa_markdown ol > li {
  margin-block: 12px;
  padding-inline-start: 4px;
}

.dvBGRa_markdown a {
  font: var(--font-body-2);
  color: var(--color-text-link);
  text-underline-offset: 2px;
  text-decoration: none;
}

.dvBGRa_markdown a:hover {
  text-decoration: underline;
}

.dvBGRa_markdown a:active {
  color: var(--color-primary-pressed);
}

.dvBGRa_markdown h1 {
  font: var(--font-title-1);
  margin: 24px 0;
}

.dvBGRa_markdown h2 {
  font: var(--font-title-2);
  color: var(--color-text-secondary);
  margin: 24px 0 12px;
}

.dvBGRa_markdown p {
  font: var(--font-body-2);
  margin: 12px 0;
}

.dvBGRa_markdown table {
  border: 1px solid var(--color-divider);
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
  word-break: break-word;
}

.dvBGRa_markdown table thead th {
  font: var(--font-subhead-2);
  color: var(--color-text);
  border-bottom: 1px solid var(--color-divider);
  text-align: left;
  padding: 12px;
}

.dvBGRa_markdown table tbody td {
  font: var(--font-body-2);
  border-bottom: 1px solid var(--color-divider);
  padding: 12px;
}

.dvBGRa_markdown table + table {
  margin-top: 12px;
}

.dvBGRa_inlineCode {
  background: var(--color-layer-2);
  font: var(--font-body-2);
  border-radius: 4px;
  padding: 4px;
}

@keyframes OSAXSW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes OSAXSW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.OSAXSW_status {
  font: var(--font-body-2);
  align-items: center;
  display: flex;
}

.OSAXSW_status .OSAXSW_icon {
  width: 10px;
  height: 10px;
  background: var(--color-success-70);
  border-radius: 50%;
  margin-right: 8px;
}

.OSAXSW_status.OSAXSW_disabled .OSAXSW_icon {
  background: var(--color-neutral-70);
}

.OSAXSW_status.OSAXSW_outlined {
  background: var(--color-success-99);
  font: var(--font-label-3);
  border-radius: 10px;
  padding: 2px 8px;
}

.OSAXSW_status.OSAXSW_outlined.OSAXSW_disabled {
  background: var(--color-neutral-variant-95);
}

@keyframes nTXhUq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nTXhUq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nTXhUq_platforms {
  margin-top: 24px;
}

.nTXhUq_platforms .nTXhUq_title {
  font: var(--font-label-2);
  margin-bottom: 12px;
}

@keyframes BP-sSa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BP-sSa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BP-sSa_connector {
  border: 1px solid var(--color-neutral-90);
  border-radius: 16px;
  padding: 12px;
}

.BP-sSa_logo {
  background-color: var(--color-layer-2);
  width: 40px;
  height: 40px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.BP-sSa_logo:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BP-sSa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.BP-sSa_name {
  background-color: var(--color-layer-2);
  width: 50px;
  height: 16px;
  border-radius: 8px;
  margin-bottom: 4px;
  position: relative;
  overflow: hidden;
}

.BP-sSa_name:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BP-sSa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.BP-sSa_description {
  background-color: var(--color-layer-2);
  height: 14px;
  border-radius: 8px;
  margin-bottom: 2px;
  position: relative;
  overflow: hidden;
}

.BP-sSa_description:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BP-sSa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.BP-sSa_description.BP-sSa_shortDescription {
  width: 50%;
}

@keyframes zQCxZG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zQCxZG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.zQCxZG_body .zQCxZG_connectorGroup {
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  display: grid;
}

@media screen and (max-width: 850px) {
  .zQCxZG_body .zQCxZG_connectorGroup {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 668px) {
  .zQCxZG_body .zQCxZG_connectorGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .zQCxZG_body .zQCxZG_connectorGroup {
    grid-template-columns: repeat(1, 1fr);
  }
}

.zQCxZG_body .zQCxZG_connectorGroup.zQCxZG_medium {
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 500px) {
  .zQCxZG_body .zQCxZG_connectorGroup.zQCxZG_medium {
    grid-template-columns: repeat(1, 1fr);
  }
}

.zQCxZG_body .zQCxZG_connectorGroup.zQCxZG_large {
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 668px) {
  .zQCxZG_body .zQCxZG_connectorGroup.zQCxZG_large {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .zQCxZG_body .zQCxZG_connectorGroup.zQCxZG_large {
    grid-template-columns: repeat(1, 1fr);
  }
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector {
  font: var(--font-body-2);
  display: flex;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_content {
  flex: 1;
  margin-left: 12px;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_content .zQCxZG_name {
  font: var(--font-label-2);
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  padding-right: 12px;
  display: -webkit-box;
  overflow: hidden;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_content .zQCxZG_connectorId {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_content .zQCxZG_description {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-top: 4px;
  display: -webkit-box;
  overflow: hidden;
}

@keyframes _5XC9fq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _5XC9fq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._5XC9fq_tip {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: 2px;
}

._5XC9fq_error {
  color: var(--color-text);
  font: var(--font-body-2);
  background-color: var(--color-danger-toast-background);
  border-radius: 8px;
  align-items: center;
  margin-top: 2px;
  padding: 12px 16px;
  display: flex;
}

._5XC9fq_error ._5XC9fq_icon {
  margin-right: 12px;
}

._5XC9fq_error ._5XC9fq_content span {
  font-weight: bold;
}

._5XC9fq_error ._5XC9fq_content > ul {
  padding-left: 12px;
}

._5XC9fq_fieldButton {
  margin-top: 8px;
}

._5XC9fq_trailingIcon {
  width: 16px;
  height: 16px;
}

@keyframes IuyFQG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes IuyFQG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.IuyFQG_container {
  border: 1px solid var(--color-border);
  border-radius: 6px;
  outline: 3px solid #0000;
  padding: 8px 12px;
}

.IuyFQG_container:focus-within {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.IuyFQG_container.IuyFQG_error {
  border-color: var(--color-error);
}

.IuyFQG_container.IuyFQG_error:focus-within {
  outline-color: var(--color-danger-focused);
}

.IuyFQG_container textarea {
  width: 100%;
  height: 100%;
  color: var(--color-text);
  font: var(--font-body-2);
  resize: none;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.IuyFQG_container textarea::placeholder {
  color: var(--color-placeholder);
}

@keyframes rgpEcG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rgpEcG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.rgpEcG_description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: 2px;
}

@keyframes _6aCFTG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _6aCFTG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._6aCFTG_copyToClipboard {
  display: block;
}

._6aCFTG_description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: 2px;
}

@keyframes _8EI5Va_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8EI5Va_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8EI5Va_fields {
  align-items: flex-end;
  margin-bottom: 4px;
  display: flex;
}

._8EI5Va_fields ._8EI5Va_textField {
  width: 100%;
}

._8EI5Va_fields ._8EI5Va_send {
  margin-bottom: 1px;
  margin-left: 6px;
}

._8EI5Va_error {
  font: var(--font-body-2);
  color: var(--color-error);
}

._8EI5Va_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

@keyframes -rCXFa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -rCXFa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-rCXFa_tabs {
  align-items: center;
  display: flex;
}

.-rCXFa_tabs .-rCXFa_tab {
  font: var(--font-label-2);
  color: var(--color-text);
  background: var(--color-layer-1);
  border: 1px solid var(--color-surface-5);
  border-radius: 100px;
  align-items: center;
  padding: 6px 12px;
  text-decoration: none;
  display: flex;
}

.-rCXFa_tabs .-rCXFa_tab:not(:last-child) {
  margin-right: 16px;
}

.-rCXFa_tabs .-rCXFa_tab .-rCXFa_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
  color: var(--color-text-link);
  margin-right: 4px;
}

.-rCXFa_tabs .-rCXFa_tab.-rCXFa_active {
  background: var(--color-inverse-primary);
  color: var(--color-white);
  border-color: var(--color-inverse-primary);
}

.-rCXFa_tabs .-rCXFa_tab.-rCXFa_active .-rCXFa_icon {
  color: var(--color-button-icon);
}

@keyframes Y_Pa3G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Y_Pa3G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Y_Pa3G_connectorType {
  background: var(--color-surface-variant);
  color: var(--color-text);
  font: var(--font-label-3);
  border-radius: 10px;
  padding: 2px 8px;
}

@keyframes ke03AG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ke03AG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ke03AG_header {
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  display: flex;
}

.ke03AG_header > :not(:first-child) {
  margin-left: 24px;
}

.ke03AG_header .ke03AG_operations {
  align-items: center;
  display: flex;
}

.ke03AG_header .ke03AG_operations .ke03AG_moreIcon {
  color: var(--color-text-secondary);
}

.ke03AG_header .ke03AG_operations > :not(:first-child) {
  margin-left: 8px;
}

.ke03AG_header .ke03AG_metadata {
  flex: 1;
}

.ke03AG_header .ke03AG_metadata > div {
  align-items: center;
  display: flex;
}

.ke03AG_header .ke03AG_metadata > div:not(:first-child) {
  margin-top: 8px;
}

.ke03AG_header .ke03AG_metadata > div > :not(:first-child) {
  margin-left: 8px;
}

.ke03AG_header .ke03AG_metadata .ke03AG_name {
  font: var(--font-title-1);
  color: var(--color-text);
}

.ke03AG_header .ke03AG_metadata .ke03AG_factoryName {
  background: var(--color-surface-variant);
  color: var(--color-text);
  font: var(--font-label-3);
  border-radius: 10px;
  padding: 2px 8px;
}

.ke03AG_header .ke03AG_metadata .ke03AG_text {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
}

.ke03AG_header .ke03AG_metadata .ke03AG_verticalBar {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 12px;
}

.ke03AG_codeEditor {
  margin-bottom: 24px;
}

.ke03AG_resetIcon {
  color: var(--color-text-secondary);
}

.ke03AG_readme {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  margin: 24px;
  padding: 0 24px;
}

@keyframes LfdwjW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes LfdwjW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

div.LfdwjW_notice {
  margin: 16px 0 0;
}

@keyframes FSq9Wq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FSq9Wq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FSq9Wq_tag {
  font: var(--font-label-3);
  background-color: var(--color-alert-container);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 10px;
  padding: 2px 6px;
}

@keyframes _2mHiHa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _2mHiHa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._2mHiHa_container {
  align-items: center;
  display: flex;
}

._2mHiHa_logoContainer {
  width: 40px;
  height: 40px;
  background-color: var(--color-hover);
  border-radius: 8px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

._2mHiHa_logo {
  width: 28px;
  height: 28px;
}

._2mHiHa_previewTitle {
  align-items: center;
  display: flex;
}

._2mHiHa_previewTitle > :first-child {
  margin-right: 8px;
}

._2mHiHa_platforms, ._2mHiHa_platforms ._2mHiHa_platform {
  align-items: center;
  display: flex;
}

._2mHiHa_platforms ._2mHiHa_platform:not(:last-child):after {
  content: "";
  width: 0;
  height: 12px;
  border-right: 1px solid var(--color-border);
  margin: 0 4px;
}

._2mHiHa_platforms ._2mHiHa_platform svg {
  margin-right: 4px;
}

@keyframes WG8Pea_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes WG8Pea_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.WG8Pea_field {
  align-items: center;
  display: flex;
}

.WG8Pea_field .WG8Pea_tipButton {
  margin-left: 2px;
}

.WG8Pea_title {
  font: var(--font-label-2);
}

.WG8Pea_content {
  font: var(--font-body-2);
}

@keyframes _8ugH2W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8ugH2W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8ugH2W_container {
  background-color: var(--color-base);
  height: 100vh;
  flex-direction: column;
  display: flex;
  overflow-x: auto;
}

._8ugH2W_container ._8ugH2W_header {
  height: 64px;
  background: none;
  align-items: center;
  padding: 0 84px 0 8px;
  display: flex;
}

._8ugH2W_container ._8ugH2W_header button {
  margin-left: 16px;
}

._8ugH2W_container ._8ugH2W_header ._8ugH2W_separator {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px 0 16px;
}

._8ugH2W_container ._8ugH2W_header ._8ugH2W_closeIcon {
  color: var(--color-text-secondary);
}

._8ugH2W_container ._8ugH2W_content {
  min-width: min-content;
  flex: 1;
  justify-content: center;
  padding: 8px 24px 24px;
  display: flex;
  overflow: auto;
}

._8ugH2W_container ._8ugH2W_content > * {
  max-width: 800px;
  min-width: 400px;
  flex: 1;
}

._8ugH2W_container ._8ugH2W_content ._8ugH2W_readme {
  background-color: var(--color-layer-1);
  border: 1.5px solid var(--color-focused-variant);
  border-radius: 16px;
  flex-direction: column;
  margin: 0 24px 0 0;
  display: flex;
  position: sticky;
  top: 0;
  overflow-y: auto;
}

._8ugH2W_container ._8ugH2W_content ._8ugH2W_readme ._8ugH2W_readmeTitle {
  font: var(--font-title-2);
  border-bottom: 1px solid var(--color-focused-variant);
  padding: 20px 24px 16px;
}

._8ugH2W_container ._8ugH2W_content ._8ugH2W_readme ._8ugH2W_readmeContent {
  flex: 1;
  padding: 0 24px 16px;
}

._8ugH2W_container ._8ugH2W_content ._8ugH2W_setup {
  padding-bottom: 24px;
}

._8ugH2W_container ._8ugH2W_content ._8ugH2W_setup ._8ugH2W_block {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 0 24px 24px;
}

._8ugH2W_container ._8ugH2W_content ._8ugH2W_setup ._8ugH2W_block ._8ugH2W_blockTitle {
  font: var(--font-title-2);
  align-items: center;
  gap: 16px;
  padding: 20px 0 24px;
  display: flex;
}

._8ugH2W_container ._8ugH2W_content ._8ugH2W_setup ._8ugH2W_block ._8ugH2W_blockTitle ._8ugH2W_number {
  width: 28px;
  height: 28px;
  background-color: var(--color-focused-variant);
  color: var(--color-primary);
  font: var(--font-title-2);
  text-align: center;
  border-radius: 50%;
  line-height: 28px;
}

._8ugH2W_container ._8ugH2W_content ._8ugH2W_setup ._8ugH2W_footer {
  justify-content: right;
  padding-bottom: 40px;
  display: flex;
}

._8ugH2W_container ._8ugH2W_content form + div {
  margin-top: 24px;
}

@keyframes _3oYvha_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3oYvha_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3oYvha_container ._3oYvha_notice {
  margin: 16px 0 0;
}

@keyframes G05ORa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes G05ORa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.G05ORa_container .G05ORa_tabs {
  margin-top: 16px;
}

.G05ORa_container .G05ORa_connectorName {
  width: 360px;
}

.G05ORa_container .G05ORa_empty {
  text-align: center;
  font-size: var(--font-body-2);
}

.G05ORa_container .G05ORa_emptyLine {
  padding: 8px 0;
}

@keyframes bk57eW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes bk57eW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.bk57eW_number {
  font: var(--font-headline-3);
}

.bk57eW_delta {
  font: var(--font-title-2);
  color: var(--color-success-50);
  align-items: center;
  display: flex;
}

.bk57eW_delta.bk57eW_down {
  color: var(--color-error-50);
}

.bk57eW_block {
  flex: 1;
}

.bk57eW_block:not(:last-child) {
  margin-right: 16px;
}

.bk57eW_block.bk57eW_bordered {
  border: 1px solid var(--color-divider);
  width: 360px;
  flex: unset;
}

.bk57eW_block .bk57eW_title {
  font: var(--font-title-2);
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.bk57eW_block .bk57eW_title .bk57eW_toggleTipButton {
  margin-left: 2px;
}

.bk57eW_block .bk57eW_content {
  align-items: baseline;
  display: flex;
}

.bk57eW_block .bk57eW_content .bk57eW_number {
  flex: 1;
}

.bk57eW_block.bk57eW_plain {
  padding: 0;
}

.bk57eW_block.bk57eW_plain .bk57eW_title {
  font: var(--font-title-2);
  margin-bottom: 24px;
}

.bk57eW_block.bk57eW_plain .bk57eW_content {
  align-items: center;
  display: flex;
}

.bk57eW_block.bk57eW_plain .bk57eW_content .bk57eW_number {
  flex: 0;
  margin-right: 8px;
}

@keyframes hGh_LW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes hGh_LW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.hGh_LW_chartTooltip {
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  box-shadow: var(--shadow-2);
  text-align: center;
  border-radius: 4px;
  padding: 8px 12px;
}

.hGh_LW_chartTooltip .hGh_LW_value {
  font: var(--font-label-2);
  color: var(--color-text);
}

.hGh_LW_chartTooltip .hGh_LW_label {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
}

@keyframes grVxOq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes grVxOq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.grVxOq_title {
  background-color: var(--color-layer-2);
  height: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
}

.grVxOq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite grVxOq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.grVxOq_number {
  background-color: var(--color-layer-2);
  height: 32px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.grVxOq_number:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite grVxOq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.grVxOq_blocks {
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.grVxOq_block {
  flex: 1;
}

.grVxOq_block:not(:last-child) {
  margin-right: 16px;
}

.grVxOq_dau {
  width: 300px;
}

.grVxOq_curve {
  background-color: var(--color-layer-2);
  height: 168px;
  border-radius: 8px;
  margin: 40px 0 24px;
  position: relative;
  overflow: hidden;
}

.grVxOq_curve:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite grVxOq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.grVxOq_activeBlocks {
  align-items: center;
  display: flex;
}

.grVxOq_activeBlocks .grVxOq_block {
  border: 1px solid var(--color-divider);
  width: 360px;
  flex: unset;
}

@keyframes aADheW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes aADheW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.aADheW_container {
  flex-direction: column;
  padding-bottom: 24px;
  display: flex;
  overflow-y: auto;
}

.aADheW_header {
  margin-bottom: 24px;
}

.aADheW_header .aADheW_title {
  font: var(--font-title-1);
  color: var(--color-text);
}

.aADheW_header .aADheW_subtitle {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 4px;
  padding-right: 24px;
}

.aADheW_blocks {
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.aADheW_activeCard {
  position: relative;
}

.aADheW_activeCard .aADheW_datePicker {
  width: 160px;
  position: absolute;
  top: 24px;
  right: 24px;
}

.aADheW_curve {
  width: 100%;
  height: 168px;
  margin: 40px 0 24px;
}

@keyframes IgTfaG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes IgTfaG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.IgTfaG_card {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  padding: 24px 32px;
  display: flex;
}

.IgTfaG_card .IgTfaG_icon {
  background-color: var(--color-layer-2);
  width: 48px;
  height: 48px;
  border-radius: 8px;
  margin-right: 24px;
  position: relative;
  overflow: hidden;
}

.IgTfaG_card .IgTfaG_icon:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite IgTfaG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.IgTfaG_card .IgTfaG_wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.IgTfaG_card .IgTfaG_wrapper .IgTfaG_title {
  background-color: var(--color-layer-2);
  width: 113px;
  height: 20px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.IgTfaG_card .IgTfaG_wrapper .IgTfaG_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite IgTfaG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.IgTfaG_card .IgTfaG_wrapper .IgTfaG_subtitle {
  background-color: var(--color-layer-2);
  width: 453px;
  height: 20px;
  border-radius: 8px;
  margin-top: 4px;
  position: relative;
  overflow: hidden;
}

.IgTfaG_card .IgTfaG_wrapper .IgTfaG_subtitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite IgTfaG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.IgTfaG_card .IgTfaG_button {
  background-color: var(--color-layer-2);
  width: 129px;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.IgTfaG_card .IgTfaG_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite IgTfaG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.IgTfaG_card + .IgTfaG_card {
  margin-top: 16px;
}

@keyframes vUugRG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vUugRG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vUugRG_container {
  flex-direction: column;
  padding-bottom: 24px;
  display: flex;
  overflow-y: auto;
}

.vUugRG_header {
  margin-bottom: 24px;
}

.vUugRG_header .vUugRG_title {
  font: var(--font-title-1);
  color: var(--color-text);
}

.vUugRG_header .vUugRG_subtitle {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  align-items: center;
  margin-top: 4px;
  padding-right: 24px;
  display: flex;
}

.vUugRG_header .vUugRG_subtitle .vUugRG_hideButton {
  display: inline-block;
}

.vUugRG_card {
  padding: 24px 32px;
  display: flex;
  position: relative;
}

.vUugRG_card .vUugRG_icon {
  width: 48px;
  height: 48px;
  margin-right: 24px;
}

.vUugRG_card .vUugRG_wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.vUugRG_card .vUugRG_wrapper .vUugRG_title {
  font: var(--font-title-2);
  color: var(--color-text);
}

.vUugRG_card .vUugRG_wrapper .vUugRG_subtitle {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.vUugRG_card .vUugRG_button {
  min-width: 120px;
}

.vUugRG_card + .vUugRG_card {
  margin-top: 16px;
}

.kSGQ4G_text {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

@keyframes Qw8NkG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Qw8NkG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Qw8NkG_container {
  width: 100%;
  font: var(--font-label-2);
}

.Qw8NkG_container .Qw8NkG_title {
  margin-bottom: 4px;
}

.Qw8NkG_container .Qw8NkG_actionButton {
  display: inline-flex;
}

.Qw8NkG_container .Qw8NkG_actionButton + .Qw8NkG_actionButton {
  margin-left: 16px;
}

.Qw8NkG_container .Qw8NkG_wrapper {
  align-items: center;
  display: flex;
}

.Qw8NkG_container .Qw8NkG_icon {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.Qw8NkG_container table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid var(--color-neutral-variant-90);
  border-radius: 8px;
}

.Qw8NkG_container table td {
  font: var(--font-body-2);
  height: 64px;
  border-bottom: 1px solid var(--color-neutral-variant-90);
  padding: 0 24px;
}

.Qw8NkG_container table td:first-child {
  max-width: 248px;
  width: 25%;
  font: var(--font-label-2);
}

.Qw8NkG_container table td:last-child {
  text-align: right;
  width: 25%;
}

.Qw8NkG_container table td:last-child .Qw8NkG_wrapper {
  justify-content: flex-end;
}

.Qw8NkG_container table td > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 320px;
  display: inline-block;
  overflow: hidden;
}

.Qw8NkG_container table tr:last-child td {
  border-bottom: none;
}

.Qw8NkG_container:not(:first-child) {
  margin-top: 24px;
}

@keyframes XeRJzW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes XeRJzW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.XeRJzW_wrapper {
  align-items: center;
  display: flex;
}

.XeRJzW_wrapper svg {
  flex-shrink: 0;
  margin-right: 8px;
}

.XeRJzW_wrapper span {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 292px;
  overflow: hidden;
}

@keyframes NgX8vq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes NgX8vq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.NgX8vq_container {
  flex-direction: column;
  margin-top: 16px;
  padding-bottom: 24px;
  display: flex;
}

.NgX8vq_section {
  background: var(--color-layer-1);
  border-radius: 12px;
  padding: 24px 32px;
  display: flex;
}

.NgX8vq_section:not(:first-child) {
  margin-top: 16px;
}

.NgX8vq_titleWrapper {
  width: 296px;
  flex-shrink: 0;
  margin-right: 56px;
  padding-bottom: 24px;
}

.NgX8vq_titleWrapper > :not(:first-child) {
  margin-top: 8px;
}

.NgX8vq_title {
  background-color: var(--color-layer-2);
  width: 200px;
  height: 20px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.NgX8vq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite NgX8vq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.NgX8vq_card {
  flex-grow: 1;
}

.NgX8vq_card .NgX8vq_label {
  background-color: var(--color-layer-2);
  width: 250px;
  height: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
}

.NgX8vq_card .NgX8vq_label:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite NgX8vq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.NgX8vq_card .NgX8vq_item {
  background-color: var(--color-layer-2);
  width: 350px;
  height: 20px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.NgX8vq_card .NgX8vq_item:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite NgX8vq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.NgX8vq_card .NgX8vq_table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid var(--color-neutral-variant-90);
  border-radius: 8px;
}

.NgX8vq_card .NgX8vq_table td {
  font: var(--font-body-2);
  height: 64px;
  border-bottom: 1px solid var(--color-neutral-variant-90);
  padding: 0 24px;
}

.NgX8vq_card .NgX8vq_table td:first-child {
  max-width: 248px;
  width: 25%;
}

.NgX8vq_card .NgX8vq_table td:first-child .NgX8vq_item {
  width: 150px;
}

.NgX8vq_card .NgX8vq_table td:last-child {
  text-align: right;
  width: 25%;
}

.NgX8vq_card .NgX8vq_table td:last-child .NgX8vq_item {
  width: 100px;
}

.NgX8vq_card .NgX8vq_table tr:last-child td {
  border-bottom: none;
}

@media screen and (max-width: 1080px) {
  .NgX8vq_section {
    flex-direction: column;
  }

  .NgX8vq_titleWrapper {
    width: 100%;
    margin-right: unset;
  }

  .NgX8vq_card {
    flex-grow: unset;
  }
}

@keyframes shIkWq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shIkWq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.shIkWq_container {
  font: var(--font-body-2);
}

p {
  margin: 0;
}

p + p {
  margin-top: 24px;
}

.shIkWq_mail {
  font-weight: bold;
}

@keyframes HzVHqa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes HzVHqa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.HzVHqa_content {
  margin-top: 16px;
  padding-bottom: 24px;
}

.HzVHqa_content > div + div {
  margin-top: 16px;
}

.HzVHqa_deleteAccount {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  flex: 1;
  align-items: center;
  padding: 16px;
  display: flex;
}

.HzVHqa_deleteAccount .HzVHqa_description {
  font: var(--font-body-2);
  margin-right: 8px;
}

@keyframes DrHO0W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes DrHO0W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.DrHO0W_checkbox {
  align-items: center;
  display: flex;
  position: relative;
}

.DrHO0W_checkbox .DrHO0W_icon {
  width: 20px;
  height: 20px;
}

.DrHO0W_checkbox .DrHO0W_icon .DrHO0W_border {
  fill: var(--color-text-secondary);
}

.DrHO0W_checkbox .DrHO0W_icon .DrHO0W_background {
  fill: var(--color-layer-1);
}

.DrHO0W_checkbox .DrHO0W_icon.DrHO0W_checked .DrHO0W_background {
  fill: var(--color-primary);
}

.DrHO0W_checkbox .DrHO0W_icon.DrHO0W_disabled .DrHO0W_background {
  fill: var(--color-checkbox-disabled-background);
}

.DrHO0W_checkbox .DrHO0W_icon.DrHO0W_disabled .DrHO0W_border {
  fill: var(--color-border);
}

.DrHO0W_checkbox .DrHO0W_icon.DrHO0W_checked.DrHO0W_disabled .DrHO0W_background {
  fill: var(--color-checkbox-checked-disabled-background);
}

.DrHO0W_checkbox .DrHO0W_wrapper {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.DrHO0W_checkbox .DrHO0W_tooltipAnchor {
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.DrHO0W_checkbox .DrHO0W_label {
  font: var(--font-body-2);
  color: var(--color-text);
  white-space: nowrap;
  cursor: inherit;
}

.DrHO0W_checkbox.DrHO0W_disabled .DrHO0W_wrapper {
  cursor: not-allowed;
}

@keyframes _79gXNq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _79gXNq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._79gXNq_container {
  background: var(--color-base);
  padding: 20px 0;
}

._79gXNq_wrapper {
  width: 640px;
  min-height: 640px;
  background: var(--color-layer-1);
  border-radius: 16px;
  flex-direction: column;
  padding: 104px 120px;
  display: flex;
  position: relative;
}

._79gXNq_wrapper > * {
  margin-bottom: 16px;
}

._79gXNq_backButton {
  color: var(--color-text);
  align-items: center;
  display: flex;
  position: absolute;
  top: 24px;
  left: 16px;
}

._79gXNq_backButton:active {
  color: var(--color-primary-pressed);
}

._79gXNq_backButton:not(:disabled):hover {
  color: var(--color-primary-hover);
  text-decoration: none;
}

._79gXNq_subtitle {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

._79gXNq_title {
  font: var(--font-headline-2);
}

._79gXNq_title + ._79gXNq_subtitle {
  margin-top: -12px;
}

._79gXNq_strong {
  font-weight: 600;
}

@keyframes oZB0cq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes oZB0cq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.oZB0cq_wrapper {
  justify-content: space-between;
  display: flex;
}

.oZB0cq_input {
  width: 44px;
  height: 44px;
  text-align: center;
  color: var(--color-text-primary);
  border: 1px solid var(--color-border);
  caret-color: var(--color-primary);
  -webkit-appearance: textfield;
  appearance: textfield;
  background: none;
  border-radius: 8px;
  outline: 3px solid #0000;
}

.oZB0cq_input:focus {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.oZB0cq_input::-webkit-outer-spin-button, .oZB0cq_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.oZB0cq_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
  margin-left: 2px;
}

.KTc0zW_message {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.KTc0zW_link:not(:disabled):hover {
  text-decoration: none;
}

.bsdY_q_link:not(:disabled):hover {
  text-decoration: none;
}

@keyframes Tm4JdG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Tm4JdG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Tm4JdG_withTable {
  height: 100%;
}

.Tm4JdG_header {
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  display: flex;
}

.Tm4JdG_header .Tm4JdG_info .Tm4JdG_name {
  font: var(--font-title-1);
  color: var(--color-text);
}

.Tm4JdG_header .Tm4JdG_info .Tm4JdG_meta {
  align-items: center;
  display: flex;
}

.Tm4JdG_header .Tm4JdG_info .Tm4JdG_meta .Tm4JdG_idText {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
  margin-right: 4px;
}

.Tm4JdG_header .Tm4JdG_moreIcon {
  color: var(--color-text-secondary);
}

@keyframes G_cUGW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes G_cUGW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.G_cUGW_container {
  border: 1px solid var(--color-border);
  border-radius: 6px;
  align-items: stretch;
  display: flex;
  overflow: hidden;
}

.G_cUGW_box {
  flex-direction: column;
  flex: 1 1 0;
  display: flex;
  overflow: hidden;
}

.G_cUGW_boxTopBar {
  height: 52px;
  border-bottom: 1px solid var(--color-border);
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.G_cUGW_boxContent {
  flex: 1 1 0;
  overflow-y: auto;
}

.G_cUGW_emptyBoxContent {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.G_cUGW_boxPagination {
  height: 40px;
  border-top: 1px solid var(--color-border);
  padding-right: 16px;
}

.G_cUGW_verticalBar {
  border-left: 1px solid var(--color-border);
  width: 0;
}

@keyframes HOGL5a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes HOGL5a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.HOGL5a_sourceScopeItem {
  align-items: center;
  padding: 6px 16px 6px 46px;
  display: flex;
}

.HOGL5a_sourceScopeItem .HOGL5a_name {
  font: var(--font-body-2);
  background: var(--color-neutral-95);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 6px;
  padding: 4px 8px;
  overflow: hidden;
}

.HOGL5a_sourceScopeItem .HOGL5a_icon {
  color: var(--color-text-secondary);
}

@keyframes kfGtNa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes kfGtNa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.kfGtNa_resourceItem {
  -webkit-user-select: none;
  user-select: none;
}

.kfGtNa_resourceItem .kfGtNa_title {
  align-items: center;
  padding: 6px 16px;
  display: flex;
}

.kfGtNa_resourceItem .kfGtNa_title .kfGtNa_resource {
  cursor: pointer;
  flex: 1;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.kfGtNa_resourceItem .kfGtNa_title .kfGtNa_resource .kfGtNa_caret {
  margin-right: 8px;
}

.kfGtNa_resourceItem .kfGtNa_title .kfGtNa_resource .kfGtNa_name {
  font: var(--font-label-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.kfGtNa_resourceItem .kfGtNa_title .kfGtNa_resource .kfGtNa_scopeInfo {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  flex-shrink: 0;
  margin-left: 8px;
}

.kfGtNa_resourceItem .kfGtNa_invisible {
  display: none;
}

.lgwVMa_search {
  width: 100%;
}

.lgwVMa_icon {
  color: var(--color-text-secondary);
}

@keyframes WBz-qW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes WBz-qW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.WBz-qW_targetScopeItem {
  align-items: center;
  padding: 6px 16px;
  display: flex;
}

.WBz-qW_targetScopeItem .WBz-qW_title {
  font: var(--font-body-2);
  flex: 1 1 0;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.WBz-qW_targetScopeItem .WBz-qW_title .WBz-qW_name {
  max-width: 204px;
  background: var(--color-neutral-95);
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 6px;
  flex-shrink: 0;
  padding: 4px 8px;
  overflow: hidden;
}

.WBz-qW_targetScopeItem .WBz-qW_title .WBz-qW_resourceName {
  color: var(--color-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 8px;
  overflow: hidden;
}

.WBz-qW_targetScopeItem .WBz-qW_icon {
  color: var(--color-text-secondary);
}

.WBz-qW_targetScopeItem:hover {
  background: var(--color-hover);
}

._5nfMBq_added {
  font: var(--font-label-2);
}

@keyframes _4f4-IW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _4f4-IW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._4f4-IW_roleScopesTransfer {
  height: 360px;
}

@keyframes _3ditZa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3ditZa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3ditZa_suspended {
  background: var(--color-error-container);
  color: var(--color-text);
  font: var(--font-label-3);
  border-radius: 10px;
  padding: 2px 6px;
}

@keyframes dZQiNa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes dZQiNa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.dZQiNa_item {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding: 10px 16px;
  display: flex;
}

.dZQiNa_item .dZQiNa_title {
  font: var(--font-body-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: -moz-fit-content;
  max-width: fit-content;
  flex: 1 1 0;
  margin-left: 8px;
  overflow: hidden;
}

.dZQiNa_item .dZQiNa_suspended {
  margin-left: 4px;
}

.dZQiNa_item:hover {
  background: var(--color-hover);
}

.eDTAea_search {
  width: 100%;
}

.eDTAea_icon {
  color: var(--color-text-secondary);
}

@keyframes vSkquG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vSkquG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vSkquG_item {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding: 10px 16px;
  display: flex;
}

.vSkquG_item .vSkquG_meta {
  flex: 1;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.vSkquG_item .vSkquG_meta .vSkquG_title {
  font: var(--font-body-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: -moz-fit-content;
  max-width: fit-content;
  flex: 1 1 0;
  margin-left: 8px;
  overflow: hidden;
}

.vSkquG_item .vSkquG_meta .vSkquG_suspended {
  margin: 0 4px;
}

.vSkquG_item .vSkquG_icon {
  color: var(--color-text-secondary);
}

.vSkquG_item:hover {
  background: var(--color-hover);
}

.aORtEW_added {
  font: var(--font-label-2);
}

@keyframes RTKOvG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes RTKOvG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.RTKOvG_roleUsersTransfer {
  height: 360px;
}

@keyframes FlVJdW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FlVJdW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FlVJdW_usersTable {
  color: var(--color-text);
  flex: 1;
  margin-bottom: 24px;
}

.FlVJdW_usersTable .FlVJdW_filter {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.FlVJdW_usersTable tbody td {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@keyframes HT86Ra_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes HT86Ra_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.HT86Ra_users {
  align-items: center;
  display: flex;
}

.HT86Ra_users .HT86Ra_avatars {
  flex-direction: row-reverse;
  align-items: center;
  display: flex;
}

.HT86Ra_users .HT86Ra_avatars .HT86Ra_avatar {
  border: 2px solid var(--color-layer-1);
  box-sizing: content-box;
  position: relative;
}

.HT86Ra_users .HT86Ra_avatars .HT86Ra_avatar:not(:last-child) {
  margin-left: -8px;
}

.HT86Ra_users .HT86Ra_count {
  color: var(--color-text);
  font: var(--font-body-2);
  margin-left: 8px;
}

.HT86Ra_empty {
  padding-left: 2px;
}

@keyframes EXktkG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes EXktkG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.EXktkG_search {
  width: 306px;
}

.EXktkG_description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@keyframes R5BQyG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes R5BQyG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.R5BQyG_preview {
  min-width: 480px;
  background: var(--color-layer-1);
  border-radius: 16px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.R5BQyG_preview .R5BQyG_header {
  margin: 24px 24px 4px;
  display: flex;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_title {
  font: var(--font-label-1);
  flex: 1;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_selects {
  align-items: center;
  display: flex;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_selects .R5BQyG_language {
  width: 112px;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_selects > :not(:last-child) {
  margin-right: 8px;
}

.R5BQyG_preview .R5BQyG_nav {
  border-bottom: unset;
  margin-top: 24px;
  padding: 0 24px 1px;
}

@keyframes KT-IsW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes KT-IsW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.KT-IsW_title {
  font: var(--font-title-3);
}

.KT-IsW_list {
  padding-left: 24px;
}

.KT-IsW_list li {
  word-break: break-word;
}

.KT-IsW_red {
  background-color: #dd37304d;
}

.KT-IsW_green {
  background-color: #68be6c66;
}

@keyframes _8_PeAG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8_PeAG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8_PeAG_container ._8_PeAG_description {
  font: var(--font-body-2);
}

._8_PeAG_container ._8_PeAG_content {
  font: var(--font-body-2);
  border-radius: 8px;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 12px;
  margin-top: 12px;
  display: flex;
}

._8_PeAG_container ._8_PeAG_content ._8_PeAG_section {
  background: var(--color-layer-2);
  color: var(--color-text);
  border-radius: 8px;
  flex: 1;
  padding: 20px;
}

._8_PeAG_container ._8_PeAG_content ._8_PeAG_section ._8_PeAG_title {
  font: var(--font-title-2);
  margin: 4px 0;
}

@keyframes Wc-taa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Wc-taa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Wc-taa_container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.Wc-taa_container .Wc-taa_cardTitle {
  flex-shrink: 0;
}

.Wc-taa_container .Wc-taa_error {
  margin-top: 16px;
}

.Wc-taa_container .Wc-taa_tabs {
  margin: 16px 0;
}

.Wc-taa_container .Wc-taa_content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop {
  flex: 1;
  margin-bottom: 24px;
  display: flex;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop.Wc-taa_withSubmitActionBar {
  margin-bottom: 12px;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop > * {
  min-width: 540px;
  flex: 1;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop .Wc-taa_form {
  margin-right: 24px;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop .Wc-taa_preview {
  align-self: flex-start;
  position: sticky;
  top: 16px;
}

@keyframes H0j30G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes H0j30G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.H0j30G_container .H0j30G_tabBar {
  width: 100%;
  height: 28px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_tabBar:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_content .H0j30G_card {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  padding: 24px;
}

.H0j30G_container .H0j30G_content .H0j30G_card > :not(:first-child) {
  margin-top: 24px;
}

.H0j30G_container .H0j30G_content .H0j30G_card .H0j30G_title {
  background-color: var(--color-layer-2);
  width: 80px;
  height: 16px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_card .H0j30G_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_content .H0j30G_card .H0j30G_field {
  background-color: var(--color-layer-2);
  width: 100%;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_card .H0j30G_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_content .H0j30G_card:not(:first-child) {
  margin-top: 12px;
}

.H0j30G_container .H0j30G_content .H0j30G_preview {
  flex: 1;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_header {
  width: 100%;
  height: 103px;
  background-color: var(--color-layer-1);
  border-radius: 16px 16px 0 0;
  padding: 24px;
  display: flex;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_header .H0j30G_info .H0j30G_title {
  width: 113px;
  height: 20px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_header .H0j30G_info .H0j30G_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_header .H0j30G_info .H0j30G_subtitle {
  width: 177px;
  height: 16px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 16px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_header .H0j30G_info .H0j30G_subtitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_header .H0j30G_button {
  width: 104px;
  height: 30px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-left: 10px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_header .H0j30G_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent {
  background: var(--color-surface-variant);
  height: 450px;
  border-radius: 0 0 16px 16px;
  padding: 24px;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile {
  width: 375px;
  height: 667px;
  background: var(--color-surface);
  transform-origin: top;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  transform: scale(.6);
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile .H0j30G_logo {
  width: 64px;
  height: 64px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 64px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile .H0j30G_logo:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile .H0j30G_slogan {
  width: 177px;
  height: 16px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin: 12px 0 40px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile .H0j30G_slogan:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile .H0j30G_field {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile .H0j30G_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile .H0j30G_button {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 40px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile .H0j30G_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile .H0j30G_social {
  width: 180px;
  height: 24px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_content .H0j30G_preview .H0j30G_previewContent .H0j30G_mobile .H0j30G_social:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes j3eHoW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes j3eHoW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.j3eHoW_tabContent > :not(:first-child) {
  margin-top: 12px;
}

.j3eHoW_title {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--color-neutral-variant-60);
}

.j3eHoW_radioGroup {
  margin-top: 12px;
}

.j3eHoW_formFieldDescription {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin: 4px 0 8px;
}

.j3eHoW_socialOnlyDescription {
  color: var(--color-text-secondary);
  margin-left: 4px;
}

.j3eHoW_selections > :not(:first-child) {
  margin-top: 12px;
}

.j3eHoW_primaryTag {
  color: var(--color-text-secondary);
}

.j3eHoW_method {
  margin-top: 12px;
}

.j3eHoW_primarySocial {
  margin-top: 8px;
}

.j3eHoW_darkModeTip {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  align-items: baseline;
  margin-top: 4px;
  display: flex;
}

.j3eHoW_manageLanguageButton {
  margin-top: 4px;
}

.j3eHoW_defaultLanguageDescription {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  padding-top: 8px;
}

.j3eHoW_imageFieldHeadline {
  margin-bottom: 8px;
}

@keyframes _Dgydq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _Dgydq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._Dgydq_container {
  flex-direction: column;
  display: flex;
}

._Dgydq_uploader {
  gap: 8px;
  display: flex;
}

._Dgydq_uploader ._Dgydq_logoUploader {
  flex: 2 0;
}

._Dgydq_uploader ._Dgydq_faviconUploader {
  flex: 1;
}

._Dgydq_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

._Dgydq_error {
  color: var(--color-error);
}

@keyframes aEWZJW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes aEWZJW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.aEWZJW_languageDetails {
  flex-direction: column;
  display: flex;
}

.aEWZJW_languageDetails .aEWZJW_title {
  font: var(--font-title-1);
  color: var(--color-text);
  flex-shrink: 0;
  justify-content: space-between;
  padding: 24px 20px;
  display: flex;
}

.aEWZJW_languageDetails .aEWZJW_title .aEWZJW_languageInfo {
  align-items: center;
  display: flex;
}

.aEWZJW_languageDetails .aEWZJW_title .aEWZJW_languageInfo > span {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-left: 8px;
}

.aEWZJW_languageDetails .aEWZJW_title .aEWZJW_languageInfo .aEWZJW_builtInFlag {
  font: var(--font-label-3);
  color: var(--color-text);
  background-color: var(--color-surface-variant);
  border-radius: 10px;
  padding: 2px 8px;
  display: inline-block;
}

.aEWZJW_languageDetails .aEWZJW_container {
  border-top: 1px solid var(--color-divider);
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_tableWrapper {
  padding: 0;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content {
  flex: 1;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content .aEWZJW_customValuesColumn {
  align-items: center;
  display: flex;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content .aEWZJW_clearButton {
  margin-left: 4px;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content .aEWZJW_clearIcon {
  width: 16px;
  height: 16px;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content .aEWZJW_sectionTitle {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  font: var(--font-section-head-2);
  color: var(--color-neutral-variant-60);
  background-color: var(--color-layer-light);
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content .aEWZJW_sectionDataKey {
  font: var(--font-body-2);
  color: var(--color-text);
  padding: 16px 20px;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content .aEWZJW_sectionBuiltInText {
  border: 1px solid var(--color-border);
  color: var(--color-text);
  background: var(--color-layer-2);
  border-radius: 6px;
  padding: 8px 12px;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content .aEWZJW_inputCell {
  position: relative;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content .aEWZJW_sectionInputArea {
  position: absolute;
  inset: 8px 20px;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content table {
  border: none;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content table thead tr > th {
  font: var(--font-label-2);
  color: var(--color-text);
  background-color: var(--color-layer-1);
  border-bottom: 1px solid var(--color-divider);
  padding: 4px 20px;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_content table tbody tr td {
  word-wrap: break-word;
  border: none;
  padding: 8px 20px;
}

.aEWZJW_languageDetails .aEWZJW_container .aEWZJW_footer {
  border-top: 1px solid var(--color-divider);
  height: 85px;
  flex-direction: row-reverse;
  flex-shrink: 0;
  padding: 20px;
  display: flex;
}

@keyframes I1-gGa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes I1-gGa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.I1-gGa_languageSelector .I1-gGa_input {
  position: relative;
}

.I1-gGa_languageSelector .I1-gGa_input .I1-gGa_addLanguageButton {
  width: 100%;
  height: 38px;
  background: unset;
}

.I1-gGa_languageSelector .I1-gGa_input .I1-gGa_buttonIcon {
  color: var(--color-text-secondary);
}

.I1-gGa_languageSelector .I1-gGa_dropDown {
  width: 168px;
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  max-height: 288px;
  box-shadow: var(--shadow-2);
  border-radius: 8px;
  margin: 4px 0;
  padding: 4px;
  position: absolute;
  overflow-y: auto;
}

.I1-gGa_languageSelector .I1-gGa_dropDown .I1-gGa_dropDownItem {
  width: 100%;
  cursor: pointer;
  font: var(--font-body-2);
  border-radius: 8px;
  padding: 8px;
}

.I1-gGa_languageSelector .I1-gGa_dropDown .I1-gGa_dropDownItem:hover {
  background: var(--color-hover);
}

.I1-gGa_languageSelector .I1-gGa_dropDown .I1-gGa_dropDownItem .I1-gGa_languageName {
  color: var(--color-text);
}

.I1-gGa_languageSelector .I1-gGa_dropDown .I1-gGa_dropDownItem .I1-gGa_languageTag {
  color: var(--color-text-secondary);
}

@keyframes _3FhG7W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3FhG7W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3FhG7W_languageItem {
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 6px 12px;
}

._3FhG7W_languageItem ._3FhG7W_languageName {
  color: var(--color-text);
  font: var(--font-label-2);
}

._3FhG7W_languageItem ._3FhG7W_languageTag {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
}

._3FhG7W_languageItem:hover {
  background-color: var(--color-hover-variant);
}

._3FhG7W_languageItem._3FhG7W_selected {
  background-color: var(--color-focused-variant);
}

._3FhG7W_languageItem._3FhG7W_selected ._3FhG7W_languageName, ._3FhG7W_languageItem._3FhG7W_selected ._3FhG7W_languageTag {
  color: var(--color-text-link);
}

@keyframes fvC3Ua_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fvC3Ua_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.fvC3Ua_languageNav {
  width: 185px;
  background-color: var(--color-layer-light);
  border-right: 1px solid var(--color-divider);
  flex-direction: column;
  flex-shrink: 0;
  padding: 12px 8px;
  display: flex;
}

.fvC3Ua_languageNav .fvC3Ua_languageItemList {
  flex: 1;
  margin-top: 12px;
  overflow-y: auto;
}

@keyframes RhR3EG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes RhR3EG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.RhR3EG_modalOverlay {
  background: var(--color-overlay);
  position: fixed;
  inset: 0;
}

.RhR3EG_modalOverlay .RhR3EG_modalContent {
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  position: fixed;
  inset: 0;
}

.RhR3EG_modalOverlay .RhR3EG_editor {
  max-width: 1224px;
  max-height: 85vh;
  box-shadow: var(--shadow-3);
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
}

.RhR3EG_modalOverlay .RhR3EG_editor .RhR3EG_header {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  display: flex;
}

.RhR3EG_modalOverlay .RhR3EG_editor .RhR3EG_content {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  flex-direction: row;
  flex: 1;
  display: flex;
  overflow: hidden;
}

@keyframes ZLT1Wq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ZLT1Wq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ZLT1Wq_container {
  background-color: var(--color-surface-1);
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.ZLT1Wq_container .ZLT1Wq_header {
  background-color: var(--color-layer-1);
  height: 64px;
  flex-shrink: 0;
  align-items: center;
  padding: 0 84px 0 8px;
  display: flex;
}

.ZLT1Wq_container .ZLT1Wq_header button {
  margin-left: 16px;
}

.ZLT1Wq_container .ZLT1Wq_header .ZLT1Wq_separator {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px 0 16px;
}

.ZLT1Wq_container .ZLT1Wq_header .ZLT1Wq_closeIcon {
  color: var(--color-text-secondary);
}

.ZLT1Wq_container .ZLT1Wq_content {
  flex-direction: column;
  flex: 1;
  align-items: center;
  overflow-y: auto;
}

.ZLT1Wq_container .ZLT1Wq_content .ZLT1Wq_reminder {
  width: 550px;
  margin: 0 auto 32px;
}

.ZLT1Wq_container .ZLT1Wq_content > .ZLT1Wq_form {
  padding: 24px 68px 108px;
  position: relative;
}

.ZLT1Wq_container .ZLT1Wq_main {
  max-width: 1168px;
  min-width: 604px;
  align-items: flex-start;
  margin: 0 auto;
  display: flex;
}

.ZLT1Wq_container .ZLT1Wq_main .ZLT1Wq_form {
  flex: 1;
}

.ZLT1Wq_container .ZLT1Wq_main .ZLT1Wq_form > :not(:first-child) {
  margin-top: 12px;
}

.ZLT1Wq_container .ZLT1Wq_main .ZLT1Wq_preview {
  flex: 1;
  margin-left: 32px;
  position: sticky;
  top: 16px;
}

.ZLT1Wq_container .ZLT1Wq_footer {
  background: var(--color-layer-1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ZLT1Wq_container .ZLT1Wq_footer .ZLT1Wq_footerContent {
  max-width: 1168px;
  min-width: 604px;
  justify-content: right;
  margin: 0 auto;
  padding: 24px 0;
  display: flex;
}

@keyframes vFdCpa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vFdCpa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vFdCpa_container {
  min-height: 528px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vFdCpa_content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.vFdCpa_content .vFdCpa_icon {
  width: 200px;
  height: 200px;
}

.vFdCpa_content .vFdCpa_wrapper {
  margin-left: 24px;
}

.vFdCpa_content .vFdCpa_title {
  font: var(--font-label-2);
}

.vFdCpa_content .vFdCpa_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  max-width: 350px;
  margin-bottom: 24px;
}

.vRMsLW_hide {
  display: none;
}

._6qORba_customCssCodeEditor {
  max-height: calc(100vh - 260px);
  min-height: 132px;
}

.C96xeG_dragging .C96xeG_item:hover {
  background: initial;
}

@keyframes A4GuyW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes A4GuyW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.A4GuyW_draggleItemContainer {
  transform: translate(0);
}

.A4GuyW_signInMethodItem {
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.A4GuyW_signInMethod {
  height: 44px;
  width: 100%;
  background-color: var(--color-layer-2);
  cursor: move;
  color: var(--color-text);
  border-radius: 8px;
  align-items: center;
  margin-right: 8px;
  padding: 12px 8px;
  display: flex;
}

.A4GuyW_signInMethod.A4GuyW_error {
  outline: 1px solid var(--color-error);
}

.A4GuyW_signInMethod .A4GuyW_identifier {
  width: 130px;
  font: var(--font-label-2);
  align-items: center;
  display: flex;
}

.A4GuyW_signInMethod .A4GuyW_authentication {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.A4GuyW_signInMethod .A4GuyW_authentication .A4GuyW_checkBox {
  width: 100%;
  flex-grow: 1;
}

.A4GuyW_signInMethod .A4GuyW_authentication .A4GuyW_swapButton {
  margin: 0 16px;
}

.A4GuyW_signInMethod .A4GuyW_authentication.A4GuyW_verifyCodePrimary {
  flex-direction: row-reverse;
}

.A4GuyW_signInMethod .A4GuyW_draggableIcon {
  color: var(--color-text-secondary);
  margin-right: 4px;
}

.A4GuyW_plusIcon {
  color: var(--color-text-secondary);
}

.A4GuyW_addAnotherSignInMethodDropdown {
  min-width: 208px;
}

.A4GuyW_addSignInMethodDropDown {
  min-width: unset;
}

.A4GuyW_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
}

@keyframes _8sspFa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8sspFa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8sspFa_dropdown {
  min-width: unset;
}

._8sspFa_addAnotherDropdown {
  min-width: 208px;
}

._8sspFa_plusIcon {
  color: var(--color-text-secondary);
}

._8sspFa_title {
  align-items: center;
  display: flex;
}

._8sspFa_title ._8sspFa_name {
  font: var(--font-body-2);
  margin-left: 12px;
}

._8sspFa_title ._8sspFa_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
  color: var(--color-text-secondary);
  margin-left: 4px;
}

@keyframes cK8lyq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes cK8lyq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.cK8lyq_item {
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.cK8lyq_item .cK8lyq_info {
  height: 44px;
  width: 100%;
  background-color: var(--color-layer-2);
  cursor: move;
  color: var(--color-text);
  border-radius: 8px;
  align-items: center;
  margin-right: 8px;
  padding: 12px 8px;
  display: flex;
}

.cK8lyq_item .cK8lyq_info .cK8lyq_draggableIcon {
  color: var(--color-text-secondary);
  margin-right: 12px;
}

.cK8lyq_item .cK8lyq_info .cK8lyq_name {
  font: var(--font-label-2);
  margin-left: 12px;
}

.cK8lyq_item .cK8lyq_info .cK8lyq_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
  color: var(--color-text-secondary);
  margin-left: 4px;
}

@keyframes GhWS2q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes GhWS2q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.GhWS2q_draggleItemContainer {
  transform: translate(0);
}

.GhWS2q_setUpHint {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

.GhWS2q_setUpHint .GhWS2q_setup {
  margin: 0 4px;
}

@keyframes _3ZkXhW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3ZkXhW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3ZkXhW_content {
  font: var(--font-body-2);
}

._3ZkXhW_content ._3ZkXhW_info {
  background: var(--color-neutral-variant-90);
  border-radius: 8px;
  margin-top: 24px;
  padding: 20px;
}

._3ZkXhW_content ._3ZkXhW_info ._3ZkXhW_infoLine {
  font: var(--font-body-2);
  align-items: center;
  display: flex;
}

._3ZkXhW_content ._3ZkXhW_info ._3ZkXhW_infoLine:not(:last-child) {
  margin-bottom: 8px;
}

._3ZkXhW_content ._3ZkXhW_info ._3ZkXhW_infoLine ._3ZkXhW_infoContent {
  font: var(--font-label-2);
  padding-left: 4px;
}

._3ZkXhW_content ._3ZkXhW_info ._3ZkXhW_infoLine ._3ZkXhW_operation {
  padding-left: 4px;
}

._3ZkXhW_content ._3ZkXhW_info ._3ZkXhW_infoLine ._3ZkXhW_eyeIcon {
  color: var(--color-text-secondary);
}

@keyframes tRtS8W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tRtS8W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tRtS8W_resourceLayout {
  height: 100%;
}

.tRtS8W_header {
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  display: flex;
}

.tRtS8W_header > :not(:first-child) {
  margin-left: 24px;
}

.tRtS8W_header .tRtS8W_metadata {
  flex: 1;
}

.tRtS8W_header .tRtS8W_metadata > div {
  align-items: center;
  display: flex;
}

.tRtS8W_header .tRtS8W_metadata > div > :not(:last-child) {
  margin-right: 8px;
}

.tRtS8W_header .tRtS8W_metadata .tRtS8W_title {
  font: var(--font-title-1);
  color: var(--color-text);
}

.tRtS8W_header .tRtS8W_metadata .tRtS8W_suspended {
  background: var(--color-error-container);
  color: var(--color-text);
  font: var(--font-label-3);
  border-radius: 10px;
  padding: 2px 6px;
}

.tRtS8W_header .tRtS8W_metadata .tRtS8W_subtitle {
  color: var(--color-text-secondary);
  font: var(--font-label-2);
}

.tRtS8W_header .tRtS8W_metadata .tRtS8W_text {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
}

.tRtS8W_header .tRtS8W_metadata .tRtS8W_verticalBar {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 12px;
}

.tRtS8W_icon {
  color: var(--color-text-secondary);
}

@keyframes _0fF0WG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _0fF0WG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._0fF0WG_logs {
  flex: 1;
  margin-bottom: 24px;
  overflow-y: auto;
}

@keyframes dzr31G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes dzr31G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.dzr31G_item {
  font: var(--font-body-2);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  padding: 10px 16px;
  display: flex;
}

.dzr31G_item:hover {
  background-color: var(--color-hover);
}

.dzr31G_item .dzr31G_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dzr31G_item .dzr31G_count {
  color: var(--color-text-secondary);
  flex-shrink: 0;
  margin-left: 8px;
}

.lCkk8G_search {
  width: 100%;
}

.lCkk8G_icon {
  color: var(--color-text-secondary);
}

.lCkk8G_emptyImage {
  width: 128px;
  height: 128px;
}

@keyframes w8u7Ua_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes w8u7Ua_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.w8u7Ua_item {
  font: var(--font-body-2);
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding: 10px 16px;
  display: flex;
}

.w8u7Ua_item:hover {
  background-color: var(--color-hover);
}

.w8u7Ua_item .w8u7Ua_info {
  flex: 1 1 0;
  align-items: center;
  margin-right: 8px;
  display: flex;
  overflow: hidden;
}

.w8u7Ua_item .w8u7Ua_info .w8u7Ua_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.w8u7Ua_item .w8u7Ua_info .w8u7Ua_count {
  color: var(--color-text-secondary);
  flex-shrink: 0;
  margin-left: 8px;
}

.w8u7Ua_item .w8u7Ua_icon {
  color: var(--color-text-secondary);
}

._99FUDG_icon {
  color: var(--color-text-secondary);
}

._99FUDG_added {
  font: var(--font-label-2);
}

.IhHGqa_rolesTransfer {
  height: 360px;
}

@keyframes vRnWHW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vRnWHW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vRnWHW_rolesTable {
  color: var(--color-text);
  flex: 1;
  margin-bottom: 24px;
}

.vRnWHW_rolesTable .vRnWHW_filter {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.vRnWHW_rolesTable .vRnWHW_filter .vRnWHW_searchInput {
  width: 306px;
}

.vRnWHW_rolesTable .vRnWHW_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.vRnWHW_rolesTable .vRnWHW_description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@keyframes Q4-sxa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Q4-sxa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Q4-sxa_empty {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
}

.Q4-sxa_connectorName {
  align-items: center;
  display: flex;
}

.Q4-sxa_connectorName .Q4-sxa_icon {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  flex-shrink: 0;
}

.Q4-sxa_connectorName .Q4-sxa_name {
  margin-left: 12px;
}

.Q4-sxa_connectorId {
  font: var(--font-body-2);
  align-items: center;
  font-family: Roboto Mono, monospace;
  line-height: 32px;
  display: flex;
}

.Q4-sxa_connectorId span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

@keyframes ZN2xuG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ZN2xuG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ZN2xuG_error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 24px;
}

.yPRQrG_searchInput {
  width: 338px;
}

@keyframes lQ-hZW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes lQ-hZW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.lQ-hZW_content {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.lQ-hZW_main {
  flex-grow: 1;
  padding: 0 8px;
  overflow-y: scroll;
}

.lQ-hZW_main > *, .lQ-hZW_main [class="appInsightsWrapper"] > * {
  max-width: 1168px;
  min-width: 604px;
  margin: 0 auto;
}

@keyframes hwnTFa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes hwnTFa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.hwnTFa_container {
  background-color: var(--color-surface-1);
  height: 100vh;
  min-width: 1280px;
  min-height: 806px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  display: flex;
}

.hwnTFa_container.hwnTFa_light {
  background-image: url("welcome.0827ebe6.svg");
}

.hwnTFa_container.hwnTFa_dark {
  background-image: url("welcome-dark.837d4c95.svg");
}

.hwnTFa_header {
  width: 100%;
  padding: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.hwnTFa_logo {
  height: 32px;
  width: auto;
  color: var(--color-text);
}

main {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.hwnTFa_placeholderTop {
  flex: 1;
}

.hwnTFa_placeholderBottom {
  flex: 2;
}

.hwnTFa_content {
  width: 585px;
  margin-left: 98px;
}

.hwnTFa_title {
  letter-spacing: -.25px;
  color: var(--color-text);
  margin: 8px 0 12px;
  font-size: 57px;
  font-weight: 600;
  line-height: 64px;
}

.hwnTFa_description {
  font: var(--font-body-1);
  color: var(--color-text-secondary);
  margin-bottom: 32px;
}

.hwnTFa_button {
  width: 400px;
}

/*# sourceMappingURL=index.607b2b50.css.map */
