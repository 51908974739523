/* stylelint-disable selector-class-pattern */
.os-scrollbar {
  &.os-theme-dark,
  &.os-theme-light {
    &.os-scrollbar-vertical {
      width: 12px;
      padding: 8px 2px;


      > .os-scrollbar-track {
        > .os-scrollbar-handle {
          background: var(--color-neutral-variant-80);
          min-height: 40px;

          &:hover {
            background: var(--color-neutral-variant-80);
          }
        }
      }
    }
  }
}
/* stylelint-enable selector-class-pattern */
